import devAPI from "../../../api/devAPI.js";
export default {
  /**
   * Gets the vendors from the backend.
   * @param {Object} context The default context object. See {@link https://vuex.vuejs.org/guide/actions.html} for more information.
   *
   */
  async getVendors(context) {
    const token = localStorage.getItem("token"); //The token is stored in the user's browser local storage.
    /**
     * devAPI is included in the file above in the import statement.
     * This was created to simplify API calls. The first parameter is
     * the API route (e.g. "vendors"). The second is the headers object.
     * The Authorization header must be included. The Action header is
     * used to call the appropriate method on the backend. For Get requests, it will always be
     * the action along with the plural of the entity. In this case, it's
     * "Get-Vendors". We are returning the promise to the calling function.
     */
    return devAPI
      .get("vendors", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Vendors",
        },
      })
      .then((response) => {
        //We are using an arrow function. The response will always include
        //a data property. The wordpress backend will almost always have
        //a data property, which is why you see response.data.data.
        //We are commiting the data to the store. See mutations.js in this
        //module to see more.
        context.commit("setVendors", response.data.data);
      });
  },

  /**
   *
   * @param {Object} payload The vendor to edit
   */
  async editVendors(_, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI.post("vendors", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: "Edit-Vendors", //This is required for the backend to route the request properly.
      },
    });
  },

  async checkInVendor(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI
      .post("vendors", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Check-In", //This is required for the backend to route the request properly.
        },
      })
      .then(async () => {
        await context.dispatch("getVendors");
      });
  },

  async checkOutVendor(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI
      .post("vendors", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Check-Out", //This is required for the backend to route the request properly.
        },
      })
      .then(async () => {
        await context.dispatch("getVendors");
      });
  },

  async createVendor(context, payload) {
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    const action = "Create-Vendor";
    return devAPI
      .post("vendors", dataFromForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then(async () => {
        await context.dispatch("getVendors");
      });
  },

  async addBAA(context, payload) {
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    const action = "Add-BAA";
    return devAPI
      .post("vendors", dataFromForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then(() => {
        context.dispatch("getVendors");
      });
  },

  async downloadBAA(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI.post("vendors", payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
        Action: "Download-BAA",
      },
    });
  },

  async deleteVendor(context, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI
      .post("vendors", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Delete-Vendor", //This is required for the backend to route the request properly.
        },
      })
      .then(() => {
        //This mutation deletes the vendor in the Vuex store so that we can get the new vendors after deletion.
        //We are passing in the original payload which is the deleted row. This is so we can compare keys to filter.
        context.commit("deleteVendor", payload);
      });
  },

  async createworklog(context, payload) {
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("username");
    dataFromForm.user = user;
    const action = "Add-Worklog";
    return devAPI
      .post("vendors", dataFromForm, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then((response) => {
        const workLogResponse = response.data.data;
        const newWorkLog = {
          onSite: workLogResponse.onSite,
          workDescription: workLogResponse.workDescription,
          checkIn: workLogResponse.checkIn,
          checkOut: workLogResponse.checkOut,
          user: workLogResponse.user,
          key: workLogResponse.vendorKey,
          postID: workLogResponse.vendorPostID,
          rowNum: workLogResponse.vendorRowNum,
        };
        /* =============================================================================
        This is where we need to set the worklog in its vendor and add it to the store 
        ================================================================================*/
        context.commit("addWorkLog", newWorkLog);
      });
  },

  vendorEditRow(context, payload) {
    context.commit("vendorEditRow", payload);
  },

  worklogEditRow(context, payload) {
    context.commit("worklogEditRow", payload);
  },

  async sendAddWorkLog(context, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI
      .post("vendors", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Add-Worklog", //This is required for the backend to route the request properly.
        },
      })
      .then(() => {
        const formData = {
          checkIn: payload["add-workLog-checkIn"],
          checkOut: payload["add-workLog-checkOut"],
          onSite: payload["add-workLog-onSite"],
          workDescription: payload["add-workLog-workDescription"],
          rowNum: payload["add-workLog-rowNum"],
        };
        context.commit("addWorkLog", formData);
      });
  },
};
