<template>
  <div class="container" v-if="employeesData != null">
    <DataTable
      :value="employeesData"
      responsiveLayout="scroll"
      editMode="row"
      datakey="id"
      v-model:editingRows="editingRows"
      @rowEditInit="onRowEditInit"
      @rowEditCancel="onRowEditCancel"
      @rowEditSave="onRowEditComplete"
      :paginator="true"
      :rows="6"
    >
      <template #empty>No Employees were found.</template>
      <Column field="employee_first_name" header="Employee First Name">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.employee_first_name" />
        </template>
      </Column>
      <Column field="employee_last_name" header="Employee Last Name">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.employee_last_name" />
        </template>
      </Column>
      <Column field="employee_position" header="Position">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.employee_position" />
        </template>
      </Column>
      <Column field="employee_personalScore" header="Personal Score"></Column>
      <Column field="employee_email" header="Email Address">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.employee_email" />
        </template>
      </Column>
      <Column
        :rowEditor="true"
        headerStyle="width:3rem"
        bodyStyle="text-align:center"
      >
      </Column>
      <Column
        columnKey="{{'employee-delete-' + key}}"
        headerStyle="width: 6rem; text-align:center;"
        bodyStyle="text-align:center; overflow: visible;"
      >
        <template #body="slotProps">
          <i
            class="pi pi-trash"
            style="color: red; cursor: pointer"
            @click="DeleteEmployee(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <div class="p-col-3">
      <Button
        label="Add Employee"
        icon="pi pi-plus p-button-lg"
        @click="AddEmployee()"
      />
    </div>
  </div>
</template>
<script>
import Column from "primevue/column";
export default {
  components: {
    Column,
  },
  props: ["employees"],
  watch: {
    employees(newVal) {
      this.employeesData = newVal;
    },
  },
  data() {
    return {
      originalRows: {},
      editingRows: [],
      employeesData: this.employees,
    };
  },
  methods: {
    onRowEditInit(event) {
      this.originalRows[event.index] = { ...this.employeesData[event.index] };
    },

    onRowEditCancel() {
      return;
    },

    AddEmployee() {
      this.$emit("create-employee");
    },

    DeleteEmployee(data) {
      this.$emit("delete-employee", data);
    },

    async onRowEditComplete(event) {
      const originalEmail = this.originalRows[event.index].employee_email;
      event.data.employee_original_email = originalEmail;
      this.$store.dispatch("editEmployees", event.data).then(() => {
        this.$emit("employees-edited");
      });
    },
  },
};
</script>

<style scoped>
</style>