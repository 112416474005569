import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";
export default {
  /**
   * The Vuex state is what the store actually holds.
   * Here, we are holding and returning an array of employee objects.
   * The return statement allows for the state to be accessed directly,
   * but it never should be. Getters should be used to access state.
   */
  state() {
    return {
      employees: [],
      employeeNames: [],
    };
  },
  //In Vuex modules, you import the getters, actions, and mutations,
  //and then reference them below the state. See the import statements
  //above.
  getters,
  actions,
  mutations,
};
