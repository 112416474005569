<template>
  <div class="AddRoleModal">
    <AddEmployeeModal
      @role-added-successfully="roleAddedSuccessfully"
      @role-added-unsuccessful="roleAddedFailed"
      @close-modal="closeAddRoleModal"
      :openCreateModal="addRoleModalShow"
      :clickedRow="selectedEmployee"
    />
  </div>
  <div class="createEmployeeModal">
    <AddEmployeeModal
      @employee-created-successfully="employeeCreatedSuccessfully"
      @employee-creation-unsuccessful="employeeCreateFailed"
      @close-modal="closeEmployeeCreateModal"
      :openCreateModal="createModalShow"
      :canAddEmployees="canAddEmployees"
    />
  </div>
  <EmployeesDataTable
    :employees="employees"
    @employees-edited="employeesEditSuccess"
    @add-role="openAddRoleModal"
    @delete-employee="confirmDeleteEmployee"
    @create-employee="openEmployeeCreateModal"
  />
  <Toast />
  <ConfirmDialog />
</template>

<script>
import EmployeesDataTable from "../../components/employees/EmployeesDataTable.vue";
import AddEmployeeModal from "../../components/employees/AddEmployeeModal.vue";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  components: {
    EmployeesDataTable,
    AddEmployeeModal,
    ConfirmDialog,
  },
  data() {
    return {
      employees: [],
      companyEmployees: [],
      createModalShow: false,
      addRoleModalShow: false,
      selectedEmployee: null,
      numberOfLicenses: localStorage.getItem("licenses"),
    };
  },

  computed: {
    canAddEmployees() {
      return this.numberOfLicenses > this.employeeCount ? true : false;
    },
    employeeCount() {
      return this.$store.getters.getEmployeeCount;
    },
  },

  methods: {
    employeesEditSuccess() {
      this.$store.dispatch("getEmployees").then(async () => {
        this.employees = this.$store.getters.getEmployees;
      });
      this.$toast.add({
        severity: "success",
        summary: "Employee edited successfully.",
        life: 4000,
      });
    },

    openEmployeeCreateModal() {
      this.createModalShow = true;
    },

    closeEmployeeCreateModal() {
      this.createModalShow = false;
    },

    roleAddedSuccessfully() {
      //We are now getting the new values from the Vuex store.
      //This is a better approach as it is inherently reactive.
      this.employees = this.$store.getters.getEmployees;
      this.$toast.add({
        severity: "success",
        summary: "Role Added Successfully.",
        life: 5000,
      });
    },

    roleAddedFailed() {
      this.$toast.add({
        severity: "fail",
        summary: "Adding Role failed.",
        life: 5000,
      });
    },

    openAddRoleModal(clickedRow) {
      this.addRoleModalShow = true;
      this.selectedEmployee = clickedRow;
    },

    closeAddRoleModal() {
      this.addRoleModalShow = false;
    },

    employeeCreatedSuccessfully() {
      //We are now getting the new values from the Vuex store.
      //This is a better approach as it is inherently reactive.
      this.employees = this.$store.getters.getEmployees;
      this.$toast.add({
        severity: "success",
        summary: "Employee Created Successfully.",
        life: 5000,
      });
    },

    employeeCreateFailed() {
      this.$toast.add({
        severity: "fail",
        summary: "Employee Creation failed.",
        life: 5000,
      });
    },

    async confirmDeleteEmployee(deletedRow) {
      this.$confirm.require({
        message: "Are you sure you want to delete this Employee?",
        header: "Delete confirmation",
        accept: () => {
          this.$store
            .dispatch("deleteEmployee", deletedRow)
            .then(async () => {
              await this.$store.dispatch("getEmployees");
              this.employees = this.$store.getters.getEmployees ?? [];
              this.$toast.add({
                severity: "success",
                summary: "Employee deleted.",
                life: 5000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Employee delete failed.",
                life: 5000,
              });
            });
        },
      });
    },
  },

  async mounted() {
    await this.$store.dispatch("getEmployees");
    const employees = this.$store.getters.getEmployees ?? [];
    employees.length > 0 ? this.employees.push(...employees) : null;
  },
};
</script>
