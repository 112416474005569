import devAPI from "../../../api/devAPI.js";
export default {
  /**
   * Gets the applications from the backend.
   * @param {Object} context The default context object. See {@link https://vuex.vuejs.org/guide/actions.html} for more information.
   *
   */
  async getApplications(context) {
    const token = localStorage.getItem("token"); //The token is stored in the user's browser local storage.
    return devAPI
      .get("applications", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Applications",
        },
      })
      .then((response) => {
        //We are using an arrow function. The response will always include
        //a data property. The wordpress backend will almost always have
        //a data property, which is why you see response.data.data.
        //We are commiting the data to the store. See mutations.js in this
        //module to see more.
        context.commit("setApplications", response.data.data);
      });
  },
  /**
   *
   * @param {Object} payload The Application to edit
   */
  async editApplications(_, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI.post("applications", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: "Edit-Application", //This is required for the backend to route the request properly.
      },
    });
  },

  async getApplicationNames(context) {
    const token = localStorage.getItem("token"); //The token is stored in the user's browser local storage.
    return devAPI
      .get("applications", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Application-Names",
        },
      })
      .then((response) => {
        //We are using an arrow function. The response will always include
        //a data property. The wordpress backend will almost always have
        //a data property, which is why you see response.data.data.
        //We are commiting the data to the store. See mutations.js in this
        //module to see more.
        context.commit("setApplicationNames", response.data.data);
      });
  },

  async deleteApplication(_, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI.post("applications", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: "Delete-Application", //This is required for the backend to route the request properly.
      },
    });
  },
  async createApplication(_, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI.post("applications", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: "Create-Application", //This is required for the backend to route the request properly.
      },
    });
  },
};
