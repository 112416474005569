export default {
  //State is the Vuex store for this module. Payload is the assets we're passing in.
  assets(state, payload) {
    //We're storing the assets from the API call into the Vuex store.
    //Then, we can use the getAssets getter method.
    state.assets = payload;
  },

  assetsLoading(state, payload) {
    state.assetsLoading = payload;
  },

  addAsset(state, payload) {
    state.assets.push(payload);
  },

  setAssets(state, payload) {
    state.assets = payload.assets;
  },

  deleteAsset(state, payload) {
    //This receives the deleted row as a payload and filters out the array to return all rows except for the deleted row.
    state.assets = state.assets.filter((val) => val.key !== payload.key);
  },

  assetEditRow(state, payload) {
    Object.assign(state.assetEditRow, payload);
  },

  checkOutAsset(state, payload) {
    let assetRowIndex = state.assets.findIndex(
      (found) => found.key === payload.key
    );
    state.assets[assetRowIndex].checkedOut = "Yes";
  },

  checkInAsset(state, payload) {
    let assetRowIndex = state.assets.findIndex(
      (found) => found.key === payload.key
    );
    state.assets[assetRowIndex].checkedOut = "No";
  },
};
