<!-- Component template HTML -->
<template>
  <div v-if="score.companyScore != null && score.personalScore != null">
    <Card class="p-shadow-8 security-score-card">
      <template #title> Security Scores </template>
      <template #content>
        <apexchart
          :id="chartId"
          width="100%"
          type="radialBar"
          :options="chartOptions"
          :series="[score.companyScore, score.personalScore]"
          class="score-chart"
        ></apexchart>
      </template>
    </Card>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["score", "chartId", "chartType"],
  computed: {
    series() {
      let seriesArray = [];
      seriesArray.push(this.companyScore);
      seriesArray.push(this.personalScore);
      return seriesArray;
    },
  },
  data() {
    return {
      companyScore: this.score.companyScore,
      personalScore: this.score.personalScore,
      chartOptions: {
        labels: ["Company Score", "Personal Score"],
        legend: {
          show: true,
          fontSize: "16px",
          position: "bottom",
          labels: {
            useSeriesColors: true,
          },
          itemMargin: {
            horizontal: 12,
          },
        },
        colors: [
          this.setColor(this.score.companyScore),
          this.setColor(this.score.personalScore),
        ],
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            track: {
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.15,
              },
            },
            dataLabels: {
              name: {
                fontSize: "1.4rem",
                show: true,
              },
              value: {
                color: "#666",
                fontSize: "1.7rem",
                show: true,
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              legend: {
                show: false,
              },
              dataLabels: {
                name: {
                  fontSize: "1 rem",
                  show: true,
                },
                value: {
                  color: "#666",
                  fontSize: "1.2rem",
                  show: true,
                },
              },
            },
          },
        ],
      },
      value: null,
      color: "",
      isLoading: false,
    };
  },
  methods: {
    setColor(score) {
      if (60 > score) {
        return "#f30d0d";
      } else if (60 < score && score < 80) {
        return "#fff500";
      } else {
        return "#00c017";
      }
    },
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#card-container {
  align-items: center;
}
.security-score-card {
  height: 100%;
}
.score-chart:after {
  content: "";
  padding-bottom: 100%;
}
</style>