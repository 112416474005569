<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCheckOutModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-pml-3 p-pr-3">
            <label for="add-workLog-createWorkLogNotes"
              >Check Out Notes for {{ selectedVendor.businessName }}</label
            >
            <Textarea
              v-model="createWorkLogNotes"
              :autoResize="true"
              rows="5"
              cols="30"
              name="add-workLog-createWorkLogNotes"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="sendCreateWorkLog()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCheckInModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed } from "vue";

export default {
  props: ["openCheckOutModal", "parentVendor"],
  setup(props, context) {
    const createWorkLogNotes = ref("");
    const currentTime = Date.now();
    const today = new Date(currentTime);
    const selectedVendor = computed(() => props.parentVendor);
    const openModal = computed(() => props.openCheckOutModal);
    const isWaiting = ref(false);

    const sendCreateWorkLog = async () => {
      isWaiting.value = true;
      const checkedOutVendor = {
        workLog: {
          onSite: selectedVendor.value.workLog.onSite,
          checkOutNotes: createWorkLogNotes.value,
          workDescription: selectedVendor.value.workLog.workDescription,
          checkIn: selectedVendor.value.workLog.checkIn, //get current time
          checkOut: today.toLocaleString("en-us", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "America/Los_Angeles",
          }),
          checkInUser: selectedVendor.value.workLog.checkInUser,
          checkOutUser: localStorage.getItem("username"),
          rowNum: selectedVendor.value.workLog.rowNum,
        },
      };
      context.emit("vendor-check-out", checkedOutVendor);
      closeCheckInModal();
    };

    const resetForm = () => {
      createWorkLogNotes.value = "";
    };

    const closeCheckInModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    return {
      createWorkLogNotes,
      sendCreateWorkLog,
      openModal,
      closeCheckInModal,
      isWaiting,
      selectedVendor,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  height: 20rem;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>