export default {
  setEmployeeNames(state, payload) {
    state.employeeNames = payload;
  },

  setApplicationNames(state, payload) {
    state.applicationNames = payload;
  },

  editManagerTask(state, payload) {
    const oldTask = state.managerTasks.filter(
      (found) => found.key == payload.key
    );
    const dueDate = Date.parse(payload.dueDate + "T23:59:59.999-08:00");
    const today = Date.now();
    if (dueDate > today && oldTask[0].isOverdue) {
      state.managerTasksOverdue--;
      payload.isOverdue = false;
    } else if (dueDate < today && !oldTask[0].isOverdue) {
      state.managerTasksOverdue++;
      payload.isOverdue = true;
    }
  },

  addRole(state, payload) {
    state.roles.push(payload);
  },

  setRoles(state, payload) {
    state.roles = payload;
  },

  deleteRole(state, payload) {
    //This receives the deleted row as a payload and filters out the array to return all rows except for the deleted row.
    state.roles = payload;
  },

  setPermissions(state, payload) {
    state.permissions = payload;
  },

  addEmployeeToRole(state, payload) {
    let roleRowIndex = state.roles.findIndex(
      (found) => found.key === payload.key
    );
    state.roles[roleRowIndex] = payload;
  },

  setOriginalTaskRow(state, payload) {
    state.originalTaskRow = payload;
  },
  setSelectedTaskRow(state, payload) {
    state.selectedTaskRow = payload;
  },
  setOriginalRoleRow(state, payload) {
    state.originalRoleRow = payload;
  },
  setManagerTasks(state, payload) {
    state.managerTasks = payload;
  },
};
