<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeEditModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="name">Asset Name</label>

            <InputText type="text" v-model="editAssetName" name="name" />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="type">Asset Type</label>
            <Dropdown
              :modelValue="assetEditRow.type"
              @update:modelValue="assetTypeOnToggle"
              v-model="editAssetType"
              :options="typeOptions"
              optionLabel="name"
              optionValue="name"
              name="type"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="location">Asset Location</label>
            <InputText
              type="text"
              v-model="editAssetLocation"
              name="location"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="label">Asset Label</label>
            <InputText type="text" v-model="editAssetLabel" name="label" />
          </div>
          <!--Multi Select-->
          <div class="p-field p-col-12 p-md-6">
            <label for="employees">Assigned Employee(s)</label>
            <MultiSelect
              :modelValue="selectedEmployees"
              @update:modelValue="onToggle"
              :options="employeeNames"
              optionLabel="label"
              optionValue="value"
              name="employees"
              placeholder="Select employee(s) to assign"
            />
          </div>
          <div class="p-field p-col-7 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="sendEditAsset()"
            />
          </div>
          <div class="p-field p-col-7 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeEditModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  props: ["openEditModal"],
  setup(props, context) {
    const store = useStore();
    const openModal = computed(() => props.openEditModal);
    const assetEditRow = computed(() => store.getters.getAssetEditRow);
    const editAssetName = ref("");
    const editAssetType = ref([]);
    const editAssetLocation = ref("");
    const editAssetLabel = ref("");
    const editAssetEmployees = ref([]);
    const selectedEmployees = ref([]);
    const employeeNames = ref([]);
    const isWaiting = ref(false);

    const typeOptions = [
      { name: "Desktop" },
      { name: "Laptop" },
      { name: "Server" },
      { name: "Medical Device" },
      { name: "Mobile Phone" },
      { name: "Desk Phone" },
      { name: "Printer" },
      { name: "Portable Hard Drive" },
      { name: "USB Storage Drive" },
      { name: "Router" },
      { name: "Switch" },
      { name: "Modem" },
      { name: "Other" },
    ];

    const sendEditAsset = async () => {
      isWaiting.value = true;
      const editedAsset = {
        name: editAssetName.value,
        type: editAssetType.value,
        location: editAssetLocation.value,
        label: editAssetLabel.value,
        employees: selectedEmployees.value,
        key: assetEditRow.value.key,
        rowNum: assetEditRow.value.rowNum,
      };
      store
        .dispatch("editAsset", editedAsset)
        .then(() => {
          context.emit("asset-edited-successfully", editedAsset);
          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("asset-edited-unsuccessfully", error);
          console.error(error);
        });
      resetForm();
      context.emit("close-edit-modal");
    };

    const resetForm = () => {
      editAssetName.value = "";
      editAssetType.value = "";
      editAssetLocation.value = "";
      editAssetLabel.value = "";
      editAssetEmployees.value = "";
    };

    const closeEditModal = () => {
      context.emit("close-edit-modal");
      store.dispatch("editAssetRow", []);
      resetForm();
    };
    const getEmployeeNames = async () => {
      isWaiting.value = true;
      await store.dispatch("getEmployeeNames").then(() => {
        const returnedNames = store.getters.getEmployeeNames;
        let nameObjects = [];
        returnedNames.forEach((name) => {
          const nameObj = {
            label: name,
            value: name,
          };
          nameObjects.push(nameObj);
        });
        employeeNames.value = [];
        employeeNames.value.push(...nameObjects);
      });
      isWaiting.value = false;
    };

    const assetTypeOnToggle = (value) => {
      editAssetType.value = value;
    };

    function onToggle(value) {
      selectedEmployees.value = value;
    }

    //When initializing a MultiSelect component with values, you must send an array of strings, not objects.
    watch(store.getters.getAssetEditRow, (newValue) => {
      const selectedArray =
        newValue.employees !== "" ? newValue.employees.split(", ") : [];
      selectedEmployees.value = Object.assign(selectedArray);
      assetEditRow.value = Object.assign(newValue);
      editAssetName.value = newValue.name;
      editAssetType.value = newValue.type;
      editAssetLocation.value = newValue.location;
      editAssetLabel.value = newValue.label;
    });

    watch(assetEditRow.value, (newValue) => {
      editAssetName.value = newValue.name;
      editAssetType.value = newValue.type;
      editAssetLocation.value = newValue.location;
      editAssetLabel.value = newValue.label;
    });

    onMounted(() => {
      getEmployeeNames();
      editAssetName.value = assetEditRow.value.name;
      editAssetType.value = assetEditRow.value.type;
      editAssetLocation.value = assetEditRow.value.location;
      editAssetLabel.value = assetEditRow.value.label;
      selectedEmployees.value = assetEditRow.value.employees;
    });

    return {
      editAssetName,
      editAssetType,
      editAssetLocation,
      editAssetLabel,
      editAssetEmployees,
      sendEditAsset,
      openModal,
      closeEditModal,
      getEmployeeNames,
      employeeNames,
      selectedEmployees,
      typeOptions,
      isWaiting,
      assetEditRow,
      onToggle,
      assetTypeOnToggle,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  height: 25rem;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>
