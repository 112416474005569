<template>
  <div class="container" v-if="assetsData != null">
    <DataTable
      :value="assetsData"
      datakey="id"
      :paginator="true"
      :rows="6"
      :loading="isLoading"
      :sortField="type"
      :sortOrder="1"
      :responsiveLayout="scroll"
    >
      <template #empty>No Assets were found.</template>
      <Column field="name" header="Name" :sortable="true"> </Column>
      <Column field="type" header="Type" :sortable="true"> </Column>
      <Column field="location" header="Location" :sortable="true"> </Column>
      <Column field="label" header="Label" :sortable="true"> </Column>
      <Column field="employees" header="Employees" :sortable="true"> </Column>

      <Column field="checkedOut" header="Checked Out" :sortable="true">
      </Column>
      <Column
        columnKey="{{'asset-menu-' + key}}"
        headerStyle="width: 3rem; text-align:center;"
        bodyStyle="text-align:center; overflow: visible;"
      >
        <template #body="slotProps">
          <i
            class="pi pi-cog task-menu clickable"
            @click="openMenu($event, slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <Menu
      ref="assetMenu"
      :model="menu.menuItems"
      :popup="true"
      id="assetMenu"
    />
    <div class="p-col-3">
      <Button
        label="Add Asset"
        icon="pi pi-plus p-button-lg"
        @click="addAsset"
      />
    </div>
  </div>
</template>


<!-- Vue Business Logic -->
<script>
import Column from "primevue/column";
import { useStore } from "vuex";
import { ref, reactive, computed, onMounted } from "vue";

export default {
  components: {
    Column,
  },
  props: ["assets"],
  setup(props, context) {
    const store = useStore();
    const isManager =
      localStorage.getItem("isManager") == "true" ? true : false;
    const checkedInLabel = ref("");
    const editingRows = ref([]);
    const expandedRows = ref([]);
    const selectedEmployees = ref([]);
    const employeeNames = ref([]);
    const isLoading = computed(() => store.getters.areAssetsLoading);
    const isWaiting = ref(false);
    const assetsData = computed({
      get: () => store.getters.getAssets,
      set: (value) => store.commit("setAssets", value),
    });
    const clickedRow = reactive({});
    const originalRows = reactive({});
    const originalSubRows = reactive({});
    const assetMenu = ref(null);

    const menu = reactive({
      menuItems: [
        {
          label: "",
          command: (event) => {
            const assetCheckedIn = {
              checkedOut: clickedRow.checkedOut,
              rowNum: clickedRow.rowNum,
              postID: clickedRow.postID,
              key: clickedRow.key,
            };
            //Switch the label when the check out button is called
            if (event.item.label == "Check Out") {
              //Revert the label value
              event.item.label = "Check In";
              context.emit("check-out", assetCheckedIn);
            } else if (event.item.label == "Check In") {
              //revert the label value
              event.item.label = "Check Out";
              //If the asset is checked out then we call the check in function
              context.emit("check-in", assetCheckedIn);
            } else {
              console.log(
                "There was an error setting the label of the checkIn button"
              );
            }
          },
        },
      ],
    });

    function addAsset() {
      context.emit("add-asset");
    }

    function openMenu(event, data) {
      Object.assign(clickedRow, data);
      if (data.checkedOut == "No" || data.checkedOut == null) {
        this.menu.menuItems[0].label = "Check Out";
      } else {
        this.menu.menuItems[0].label = "Check In";
      }
      assetMenu.value.toggle(event);
    }

    const getEmployeeNames = async () => {
      isWaiting.value = true;
      await store.dispatch("getEmployeeNames").then(() => {
        const returnedNames = store.getters.getEmployeeNames;
        let nameObjects = [];
        returnedNames.forEach((name) => {
          const nameObj = {
            label: name,
            value: name,
          };
          nameObjects.push(nameObj);
        });
        employeeNames.value.push(...nameObjects);
      });
      isWaiting.value = false;
    };

    onMounted(() => {
      if (isManager) {
        menu.menuItems.push(
          {
            label: "Edit Asset",
            command: () => {
              store.dispatch("editAssetRow", clickedRow).then(() => {
                context.emit("edit-asset-clicked");
              });
            },
          },
          {
            label: "Delete Asset",
            command: () => {
              context.emit("delete-asset-clicked", clickedRow);
            },
          }
        );
        getEmployeeNames();
      }
    });

    return {
      checkedInLabel,
      editingRows,
      expandedRows,
      assetsData,
      clickedRow,
      originalRows,
      originalSubRows,
      assetMenu,
      menu,
      addAsset,
      selectedEmployees,
      getEmployeeNames,
      employeeNames,
      openMenu,
      isLoading,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
.p-datatable-loading-icon {
  color: #f06e39;
}
</style>