export default {
  getPermissions(state) {
    return state.permissions;
  },
  getRoles(state) {
    return state.roles;
  },
  getOriginalTaskRow(state) {
    return state.originalTaskRow;
  },
  getSelectedTaskRow(state) {
    return state.selectedTaskRow;
  },
  getOriginalRoleRow(state) {
    return state.originalRoleRow;
  },
  getManagerTasks(state) {
    return state.managerTasks;
  },
};
