<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeChangeModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div
          class="p-d-flex p-jc-center p-ai-center loading-spinner"
          v-if="isLoading"
        >
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <div
          class="changePasswordModal p-fluid p-formgrid p-grid p-jc-center p-mt-4"
        >
          <div class="p-field p-col-9">
            <label for="current">Current Password:</label>
            <Password
              v-model="currentPassword"
              v-on:keyup="resetErrors"
              :feedback="false"
              name="current"
              :class="{
                'p-invalid':
                  (validationErrors.currentPassword ||
                    validationErrors.wrongCurrentPassword) &&
                  submitted,
              }"
            />
            <small
              v-show="validationErrors.currentPassword && submitted"
              class="p-error"
              >You must enter a password.</small
            >
            <small
              v-show="validationErrors.wrongCurrentPassword"
              class="p-error"
              >Wrong password. Please re-enter your current password.</small
            >
          </div>
          <div class="p-field p-col-9">
            <label for="newpassword">New Password:</label>
            <Password
              v-model="newPassword"
              v-on:keyup="resetErrors"
              name="newpassword"
              :class="{
                'p-invalid': validationErrors.newPasswordEmpty && submitted,
              }"
            />
            <small
              v-show="validationErrors.newPasswordEmpty && submitted"
              class="p-error"
              >You must enter a new password.</small
            >
          </div>
          <div class="p-field p-col-9 p-mb-4">
            <label for="repeatpassword">Repeat New Password:</label>
            <Password
              v-model="repeatPassword"
              v-on:keyup="resetErrors"
              :feedback="false"
              name="repeatpassword"
              :class="{
                'p-invalid':
                  (validationErrors.repeatPassword && submitted) ||
                  (validationErrors.repeatPasswordEmpty && submitted),
              }"
            />
            <small
              v-show="validationErrors.repeatPassword && submitted"
              class="p-error"
              >Your new password does not match in both fields.</small
            >
            <small
              v-show="validationErrors.repeatPasswordEmpty && submitted"
              class="p-error"
              >Please enter your password again.</small
            >
          </div>
          <div class="p-field p-col-12" v-if="isSending">
            <ProgressBar mode="indeterminate" />
          </div>
          <div class="p-field p-col-4">
            <Button label="Change" @click.prevent="ChangePassword" />
          </div>
          <div class="p-field p-col-4">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeChangePasswordModal"
            />
          </div>
        </div>
      </dialog>
    </transition>
  </div>
</template>


<script>
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";

export default {
  props: ["openChangeModal"],
  watch: {
    openChangeModal: function (val) {
      this.openModal = val;
    },
  },
  data() {
    return {
      openModal: false,
      currentPassword: null,
      newPassword: null,
      repeatPassword: null,
      submitted: false,
      isSending: false,
      validationErrors: {
        currentPassword: false,
        wrongCurrentPassword: false,
        newPasswordEmpty: false,
        repeatPassword: false,
        repeatPasswordEmpty: false,
      },
      isLoading: false,
      isWaiting: false,
    };
  },
  components: {
    Password,
    ProgressBar,
  },

  methods: {
    closeChangePasswordModal() {
      this.resetErrors();
      this.resetFields();
      this.$emit("close-modal");
    },
    validatePasswords() {
      if (this.currentPassword == null || this.currentPassword.trim() == "") {
        this.validationErrors["currentPassword"] = true;
        return false;
      }
      if (this.newPassword != this.repeatPassword) {
        this.validationErrors["repeatPassword"] = true;
        return false;
      }
      if (this.newPassword == null || this.newPassword.trim() == "") {
        this.validationErrors["newPasswordEmpty"] = true;
        return false;
      }
      if (
        this.repeatPassword == null ||
        (this.repeatPassword.trim() == "" &&
          !this.newPassword == null &&
          !this.newPassword.trim() == "")
      ) {
        this.validationErrors["repeatPasswordEmpty"] = true;
        return false;
      }
      if (
        !this.validationErrors["currentPassword"] &&
        !this.validationErrors["repeatPassword"] &&
        !this.validationErrors["newPasswordEmpty"] &&
        !this.validationErrors["repeatPasswordEmpty"]
      ) {
        return true;
      } else return false;
    },

    resetErrors() {
      this.submitted = false;
      this.validationErrors["currentPassword"] = false;
      this.validationErrors["newPasswordEmpty"] = false;
      this.validationErrors["repeatPasswordEmpty"] = false;
      this.validationErrors["repeatPassword"] = false;
      this.validationErrors["wrongCurrentPassword"] = false;
    },

    resetFields() {
      this.currentPassword = null;
      this.newPassword = null;
      this.repeatPassword = null;
    },

    async ChangePassword() {
      this.submitted = true;
      if (this.validatePasswords()) {
        this.isSending = true;
        //create dipatch item
        const userPass = {
          username: localStorage.getItem("login"),
          currentPass: this.currentPassword,
          newPass: this.newPassword,
        };
        //change password dispatch
        this.$store
          .dispatch("changePassword", userPass)
          .then(() => {
            this.$emit("password-changed-successfully");
            this.isSending = false;
            this.$emit("close-modal");
            this.resetFields();
            this.resetErrors();
          })
          .catch((error) => {
            if (error.data.data === "Incorrect password.") {
              this.validationErrors["wrongCurrentPassword"] = true;
            }
            this.isSending = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.loading-spinner {
  height: 100%;
  padding-bottom: 10%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 20vh;
  width: 30rem;
  height: auto;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  /*padding: 1rem;*/
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

ul {
  list-style-type: none;
}

#edit-modal-form {
  margin: 5% 5% 0% 5%;
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>