<template>
  <div>
    <div class="createVendorModal">
      <UploadDocumentModal
        @document-uploaded-successfully="documentUploadedSuccessfully"
        @document-uploaded-unsuccessful="documentUploadFailed"
        @close-modal="closeUploadDocumentModal"
        :openUploadModal="uploadDocumentModalShow"
      />
    </div>
    <DocumentDataTable
      :documents="documents"
      @upload-document="openUploadDocumentModal"
      @delete-document="deleteDocument"
    />
    <Toast />
    <ConfirmDialog />
  </div>
</template>

<script>
import UploadDocumentModal from "../../components/documents/UploadDocumentModal.vue";
import DocumentDataTable from "../../components/documents/DocumentDataTable.vue";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  components: {
    ConfirmDialog,
    UploadDocumentModal,
    DocumentDataTable,
  },
  computed: {
    documents() {
      return this.$store.getters.getdocuments;
    },
  },
  data() {
    return {
      isWaiting: false,
      selectedRow: null,
      uploadDocumentModalShow: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("getDocuments");
  },

  methods: {
    openUploadDocumentModal() {
      this.uploadDocumentModalShow = true;
    },

    closeUploadDocumentModal() {
      this.uploadDocumentModalShow = false;
    },

    documentUploadedSuccessfully() {
      this.$toast.add({
        severity: "success",
        summary: "Document Uploaded Successfully.",
        life: 5000,
      });
    },

    documentUploadFailed() {
      this.$toast.add({
        severity: "fail",
        summary: "Document Upload failed.",
        life: 5000,
      });
    },

    deleteDocument(deletedRow) {
      this.$confirm.require({
        message: "Are you sure you want to delete this Document?",
        header: "Delete confirmation",
        accept: () => {
          this.$store
            .dispatch("deleteDocument", deletedRow)
            .then(async () => {
              await this.$store.dispatch("getDocuments");
              if (deletedRow.category === "baa") {
                this.$store.dispatch("getVendors");
              }
              this.$toast.add({
                severity: "success",
                summary: "Document Deleted.",
                life: 5000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Document Delete Failed",
                life: 5000,
              });
            });
        },
      });
    },
  },
};
</script>

<style scoped>
</style>