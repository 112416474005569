export default {
  //We are returning the assets from the Vuex state.
  //This can be called from any component using the following:
  // this.$state.getters.getAssets
  getAssets(state) {
    return state.assets;
  },

  getAssetEditRow(state) {
    return state.assetEditRow;
  },

  areAssetsLoading(state) {
    return state.assetsLoading;
  },

  getOriginalAssetRow(state) {
    return state.assetEditRow;
  },
};
