<!-- Component template HTML -->
<template>
  <div class="container">
    <div
      v-if="openEditModalProp"
      class="backdrop"
      @click="closeEditModal"
    ></div>
    <transition name="modal">
      <dialog open v-if="openEditModal">
        <div
          class="p-d-flex p-jc-center p-ai-center loading-spinner"
          v-if="isLoading"
        >
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <div class="editTaskModal">
          <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
            <ProgressSpinner
              style="width: 5vh; height: 5vh"
              strokeWidth="8"
              fill="#fff"
              animationDuration=".5s"
              class="modal-spinner"
            />
          </div>
          <form
            class="p-fluid p-formgrid p-grid p-mx-auto"
            id="edit-modal-form"
            :class="{ fadeOut: isWaiting }"
          >
            <div class="p-field p-col-12 p-md-6">
              <label for="edit-task-name">Task Name</label>
              <InputText
                type="text"
                v-model="selectedRow.name"
                name="edit-task-name"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="edit-task-dueDate">Due Date</label>
              <Calendar
                v-model="selectedRow.dueDate"
                :minDate="minDate"
                :showIcon="true"
                name="edit-task-dueDate"
              />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="edit-task-category">Category</label>
              <Dropdown
                v-model="selectedRow.category"
                :options="categoryOptions"
                optionLabel="name"
                optionValue="code"
                name="edit-task-category"
              >
                <template #value="slotProps">
                  <div v-if="slotProps.value && !slotProps.value.name">
                    <span>{{ slotProps.value }}</span>
                  </div>
                  <div v-else-if="slotProps.value && slotProps.value.name">
                    <span>{{ slotProps.value.name }}</span>
                  </div>
                  <span v-else>{{ slotProps.placeholder }}</span>
                </template>
              </Dropdown>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="edit-task-description">Task Description</label>
              <InputText
                type="text"
                v-model="selectedRow.description"
                name="edit-task-description"
              ></InputText>
            </div>
            <div class="p-field p-col-12">
              <label for="edit-task-employees">Assign Employees</label>
              <MultiSelect
                :modelValue="selectedEmployees"
                :options="employeeNames"
                optionLabel="label"
                optionValue="value"
                @update:modelValue="onToggle"
              />
            </div>
            <div class="p-field p-col-4 p-mx-auto">
              <Button
                class="p-button-raised"
                label="Submit"
                @click.prevent="sendEditTask"
              />
            </div>
            <div class="p-field p-col-4 p-mx-auto">
              <Button
                class="p-button-secondary"
                label="Cancel"
                @click.prevent="closeEditModal"
              />
            </div>
          </form>
        </div>
      </dialog>
    </transition>
  </div>
</template>


<script>
import Calendar from "primevue/calendar";
export default {
  props: ["openEditModal"],
  computed: {
    selectedRow: {
      get: function () {
        return this.$store.getters.getSelectedTaskRow;
      },
      set: function (newValue) {
        this.$store.dispatch("setSelectedTaskRow", newValue);
      },
    },

    selectedEmployees: {
      get: function () {
        return this.selectedRow?.employees;
      },
      set: function (newValue) {
        this.selectedRow.employees = newValue;
      },
    },

    minDate() {
      return new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    },
    openEditModalProp() {
      return this.openEditModal;
    },
  },
  data() {
    return {
      employeeNames: [],
      companyTasks: [],
      personalTasks: [],
      allTasks: [],
      training: {},
      selectedTaskName: "",
      selectedTaskDueDate: "",
      selectedTaskCategory: "",
      selectedTaskDescription: "",
      isLoading: false,
      isWaiting: false,
      categoryOptions: [
        { name: "Security", code: "Security" },
        { name: "Safety", code: "Safety" },
        { name: "Compliance", code: "Compliance" },
        { name: "Administrative", code: "Administrative" },
        { name: "Regulatory", code: "Regulatory" },
        { name: "Other", code: "Other" },
      ],
    };
  },
  components: {
    Calendar,
  },

  methods: {
    onToggle(value) {
      this.selectedRow.employees = [...value];
    },

    closeEditModal() {
      this.$emit("close-modal");
    },

    async sendEditTask() {
      this.isWaiting = true;
      const formData = {
        "edit-task-name": this.selectedRow.name,
        "edit-task-category": this.selectedRow.category.name
          ? this.selectedRow.category.name
          : this.selectedRow.category,
        "edit-task-dueDate": this.selectedRow.dueDate,
        "edit-task-description": this.selectedRow.description,
        "edit-task-rowNum": this.selectedRow.rowNum,
        "edit-task-postID": this.selectedRow.postID,
        "edit-task-employees": this.selectedRow.employees,
        "edit-task-key": this.selectedRow.key,
        "edit-task-isPersonal": this.selectedRow.isPersonal,
      };
      try {
        await this.$store.dispatch("sendEditManagerTask", formData).then(() => {
          this.$emit("task-edited-successfully");
        });
      } catch (error) {
        this.$emit("task-edit-failed", error);
      }

      this.isWaiting = false;
    },
  },

  async mounted() {
    await this.$store.dispatch("getEmployeeNames").then(() => {
      const names = this.$store.getters.getEmployeeNames;
      this.employeeNames = names.map((val) => {
        return {
          label: val,
          value: val,
        };
      });
    });
  },
};
</script>

<style scoped>
.loading-spinner {
  height: 100%;
  padding-bottom: 10%;
}

ul {
  list-style-type: none;
}

#edit-modal-form {
  margin: 5% 5% 0% 5%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  height: auto;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>