<template>
  <div
    class="p-d-flex p-jc-center p-ai-center loading-spinner"
    v-if="isLoading"
  >
    <ProgressSpinner
      style="width: 10vh; height: 10vh"
      strokeWidth="8"
      fill="#fff"
      animationDuration=".5s"
    />
  </div>
  <div class="toastDiv">
    <Toast group="dashboardBody" position="top-right" />
  </div>
  <div class="createTaskModal">
    <AddTaskModal
      :isPersonalTask="false"
      @task-created-successfully="taskCreated"
      @task-creation-unsuccessful="taskCreateFailed"
      @close-modal="closeCreateModal"
      :openCreateModal="createModalShow"
    />
  </div>
  <div class="editTaskModal">
    <BaseModal
      :open="editModalShow"
      @close-modal="closeEditModal"
      class="edit-task-basemodal"
    >
      <Toast />
      <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
        <ProgressSpinner
          style="width: 5vh; height: 5vh"
          strokeWidth="8"
          fill="#fff"
          animationDuration=".5s"
          class="modal-spinner"
        />
      </div>
      <form
        class="p-fluid p-formgrid p-grid p-mx-auto"
        id="edit-modal-form"
        :class="{ fadeOut: isWaiting }"
      >
        <div class="p-field p-col-12 p-md-6">
          <label for="edit-task-name">Task Name</label>
          <InputText
            type="text"
            v-model="selectedTaskRow.name"
            name="edit-task-name"
          />
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="edit-task-dueDate">Due Date</label>
          <Calendar
            v-model="selectedTaskRow.dueDate"
            :minDate="minDate"
            :showIcon="true"
            name="edit-task-dueDate"
          />
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="edit-task-category">Category</label>
          <Dropdown
            v-model="selectedTaskRow.category"
            :options="categoryOptions"
            optionLabel="name"
            optionValue="code"
            name="edit-task-category"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value && !slotProps.value.name">
                <span>{{ slotProps.value }}</span>
              </div>
              <div v-else-if="slotProps.value && slotProps.value.name">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else>{{ slotProps.placeholder }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="edit-task-description">Task Description</label>
          <InputText
            type="text"
            v-model="selectedTaskRow.description"
            name="edit-task-description"
          ></InputText>
        </div>
        <div class="p-field p-col-12" v-if="selectedTaskRow.isPersonal">
          <label for="edit-task-employees">Assign Employees</label>
          <MultiSelect
            :modelValue="selectedEmployees"
            :options="employeeNames"
            optionLabel="label"
            @update:modelValue="onToggle"
          />
        </div>
        <div class="p-field p-col-4 p-mx-auto">
          <Button
            class="p-button-raised"
            label="Submit"
            @click.prevent="sendEditTask"
          />
        </div>
        <div class="p-field p-col-4 p-mx-auto">
          <Button
            class="p-button-secondary"
            label="Cancel"
            @click.prevent="closeEditModal"
          />
        </div>
      </form>
    </BaseModal>
  </div>
  <div class="p-grid p-jc-evenly security-score-container" v-if="!isLoading">
    <SecurityScore
      v-if="
        !isLoading &&
        securityScores.companyScore != null &&
        securityScores.personalScore != null
      "
      :score="securityScores"
      chartId="companySecurityScore"
      chartType="Company Score"
      class="p-col-12 p-md-6 p-lg-4 score-chart-component"
    />

    <CompanyTaskTable
      @add-task-clicked="openCreateTaskModal"
      v-if="!isLoading"
      :tasks="allTasks"
      class="p-col-12 p-md-12 p-lg-7"
      @open-edit-modal="openEditModal"
      @delete-task="confirmDeleteTask"
      @complete-task="completeTask"
    ></CompanyTaskTable>
    <ConfirmDialog></ConfirmDialog>
    <div class="p-col-12"><h1>Your Training</h1></div>
    <Card
      v-for="t in training"
      :key="t.id"
      class="p-col-12 p-lg-3 p-p-4 p-mb-6"
    >
      <template #title>
        {{ t.title.rendered }}
      </template>
      <template #content>
        <ProgressBar :value="t.progress"> </ProgressBar>
        {{ t.completedSteps + " / " + t.totalSteps + " steps complete" }}
        <div class="P-col-12 p-p-4">
          <Button label="Go To Training" @click="sendToTraining(t.link)" />
        </div>
      </template>
    </Card>
  </div>
</template>


<script>
import SecurityScore from "../../components/dashboard/SecurityScore.vue";
import CompanyTaskTable from "../../components/dashboard/CompanyTaskTable.vue";
import BaseModal from "../../components/base/BaseModal.vue";
import Calendar from "primevue/calendar";
import axios from "axios";
import ProgressBar from "primevue/progressbar";
import ConfirmDialog from "primevue/confirmdialog";
import AddTaskModal from "../../components/dashboard/AddTaskModal.vue";
export default {
  computed: {
    allTasks() {
      return this.$store.getters.getTasks;
    },
    companyTasks() {
      return this.$store.getters.companyTasks;
    },
    personalTasks() {
      return this.$store.getters.personalTasks;
    },
    minDate() {
      return new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    },
  },
  data() {
    return {
      securityScores: {},
      employeeNames: [],
      selectedEmployees: [],
      training: {},
      selectedTaskRow: null,
      isLoading: false,
      editModalShow: false,
      createModalShow: false,
      isWaiting: false,
      deleteTaskDialog: false,
      categoryOptions: [
        { name: "Security", code: "Security" },
        { name: "Safety", code: "Safety" },
        { name: "Compliance", code: "Compliance" },
        { name: "Administrative", code: "Administrative" },
        { name: "Regulatory", code: "Regulatory" },
        { name: "Other", code: "Other" },
      ],
    };
  },
  components: {
    SecurityScore,
    CompanyTaskTable,
    BaseModal,
    Calendar,
    ProgressBar,
    ConfirmDialog,
    AddTaskModal,
  },

  methods: {
    openEditModal(selectedTasks) {
      this.selectedTaskRow = selectedTasks;
      const empArray = selectedTasks.employees.split(",");
      empArray.forEach((name) => {
        let empObj = {
          label: name,
          value: name,
        };

        this.selectedEmployees.push(empObj);
      });
      this.editModalShow = true;
    },
    completeTask(taskCompleted) {
      this.$store.dispatch("completeTask", taskCompleted).then(() => {
        this.$toast.add({
          severity: "success",
          summary: "Task successfully completed.",
          life: 5000,
          group: "dashboardBody",
        });
      });
    },
    taskCreated(newTasks) {
      this.allTasks = newTasks;
      this.$toast.add({
        severity: "success",
        summary: "Task created successfully.",
        life: 5000,
        group: "dashboardBody",
      });
      this.closeCreateModal();
    },

    taskCreateFailed() {
      this.$toast.add({
        severity: "error",
        summary: "Task creation failed.",
        life: 5000,
        position: "top-right",
      });
    },
    onToggle(value) {
      this.selectedEmployees = value;
    },
    openCreateTaskModal() {
      this.createModalShow = true;
    },
    closeCreateModal() {
      this.createModalShow = false;
    },

    closeEditModal() {
      this.selectedEmployees = [];
      this.editModalShow = false;
    },

    sendToTraining(url) {
      const baseURL =
        "https://portal.zekteck.dev/?rest_route=/simple-jwt-login/v1/autologin&JWT=";
      const token = localStorage.getItem("token");
      const redirectURL = "&redirectUrl=" + encodeURIComponent(url);
      const fullURL = baseURL + token + redirectURL;
      window.open(fullURL);
    },

    logout() {
      this.$store.dispatch("logout");
    },
    async sendEditTask() {
      this.isWaiting = true;
      const formData = {
        "edit-task-name": this.selectedTaskRow.name,
        "edit-task-category": this.selectedTaskRow.category.name
          ? this.selectedTaskRow.category.name
          : this.selectedTaskRow.category,
        "edit-task-dueDate": this.selectedTaskRow.dueDate,
        "edit-task-description": this.selectedTaskRow.description,
        "edit-task-rowNum": this.selectedTaskRow.rowNum,
        "edit-task-postID": this.selectedTaskRow.postID,
        "edit-task-employees": this.selectedEmployees.map((emp) => {
          return emp.label;
        }),
        "edit-task-key": this.selectedTaskRow.key,
        "edit-task-isPersonal": this.selectedTaskRow.isPersonal,
      };
      try {
        await this.$store.dispatch("sendEditTask", formData).then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Task Edit Successful.",
            life: 5000,
            group: "dashboardBody",
          });
          let oldRow = null;
          if (formData["edit-task-isPersonal"]) {
            oldRow = this.personalTasks.find(
              (personalTasks) => personalTasks.key == this.selectedTaskRow.key
            );
            this.personalTasks = this.$store.getters.personalTasks;
          } else if (!formData["edit-task-isPersonal"]) {
            oldRow = this.companyTasks.find(
              (companyTasks) => companyTasks.key == this.selectedTaskRow.key
            );
            this.companyTasks = this.$store.getters.companyTasks;
          }
          this.allTasks = [...this.personalTasks, ...this.companyTasks];
          const formattedDate = new Date(
            this.selectedTaskRow.dueDate
          ).toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });
          if (oldRow.isOverdue) {
            this.$store.dispatch("checkOverdueOnEdit", formattedDate);
          }

          this.selectedTaskRow.dueDate = formattedDate;
          Object.assign(oldRow, this.selectedTaskRow);
          this.closeEditModal();
        });
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Something went wrong. Error message: " + error.message,
          life: 5000,
        });
      }

      this.isWaiting = false;
    },
    async confirmDeleteTask(deletedRow) {
      this.$confirm.require({
        message: "Are you sure you want to delete this task?",
        header: "Delete confirmation",
        accept: () => {
          this.$store.dispatch("deleteTask", deletedRow).then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Task deleted.",
              group: "dashboardBody",
              life: 5000,
            });
          });
        },
      });
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      await this.$store.dispatch("loadDashboard").then(() => {
        const companySecurityScore =
          +this.$store.getters.companySecurityScore ?? 0;
        const personalSecurityScore =
          +this.$store.getters.personalSecurityScore ?? 0;

        this.securityScores.companyScore = companySecurityScore;
        this.securityScores.personalScore = personalSecurityScore;
      });
      await this.$store
        .dispatch("loadTraining")
        .then(
          axios.spread((t, p) => {
            const trainingObj = [];
            t.data.forEach(function (item) {
              const progressObj = p.data.find(
                ({ course }) => course == item.id
              );
              const progress = Math.round(
                +(
                  (progressObj["steps_completed"] /
                    progressObj["steps_total"]) *
                  100
                )
              );
              item.progress = progress;
              item.totalSteps = progressObj["steps_total"];
              item.completedSteps = progressObj["steps_completed"];
              trainingObj.push(item);
            });
            Object.assign(this.training, trainingObj);
            this.isLoading = false;
          })
        )
        .catch((error) => {
          console.dir(error);
        });
    } catch (error) {
      console.log(error);
    }
    this.$store.dispatch("getEmployeeNames").then(() => {
      const returnedNames = this.$store.getters.getEmployeeNames;
      let nameObjects = [];
      returnedNames.forEach((name) => {
        const nameObj = {
          label: name,
          value: name,
        };
        nameObjects.push(nameObj);
      });
      this.employeeNames.push(...nameObjects);
    });
  },
};
</script>

<style scoped>
#training-container {
  margin: 3% 0 5% 0;
}

.loading-spinner {
  height: 100%;
  padding-bottom: 10%;
}

ul {
  list-style-type: none;
}

#edit-modal-form {
  margin: 5% 5% 0% 5%;
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>

<style>
.overdue-task {
  background-color: #ff11001c !important;
}
</style>
