<template>
  <div class="stepsdemo-content">
    <Card>
      <template v-slot:content>
        <div class="p-grid p-fluid">
          <div class="p-field p-col-12 p-md-6">
            <label for="name">Role Name: </label>
            <InputText
              id="name"
              v-model="RoleName"
              :class="{ 'p-invalid': validationErrors.name && submitted }"
            />
            <small v-show="validationErrors.name && submitted" class="p-error"
              >Role name is required.</small
            >
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="description">Role Description: </label>
            <InputText
              id="description"
              v-model="RoleDescription"
              :class="{
                'p-invalid': validationErrors.description && submitted,
              }"
            />
            <small
              v-show="validationErrors.description && submitted"
              class="p-error"
              >Role description is required.</small
            >
          </div>
          <div class="p-field-checkbox p-col-12 p-md-6">
            <label for="isManager" class="p-mr-3">Manager Role: </label>
            <Checkbox v-model="RoleIsManager" :binary="true" />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <Button
            label="Next"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  setup(_, context) {
    const RoleName = ref("");
    const RoleDescription = ref("");
    const RoleIsManager = ref(false);
    const validationErrors = ref([]);
    function nextPage() {
      if (this.validateForm()) {
        context.emit("next-page", {
          formData: {
            name: this.RoleName,
            description: this.RoleDescription,
            isManager: this.RoleIsManager,
          },
          pageIndex: 0,
        });
      }
    }

    function validateForm() {
      return this.RoleName != "" && this.RoleDescription != "";
    }

    return {
      nextPage,
      validateForm,
      RoleName,
      RoleDescription,
      RoleIsManager,
      validationErrors,
    };
  },
};
</script>

<style scoped>
</style>