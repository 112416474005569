<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCreateModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="create-task-name">Task Name</label>
            <InputText
              type="text"
              v-model="createTaskName"
              name="create-task-name"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="create-task-dueDate">Due Date</label>
            <Calendar
              v-model="createTaskDueDate"
              :minDate="minDate"
              :showIcon="true"
              name="create-task-dueDate"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="create-task-category">Category</label>
            <Dropdown
              v-model="createTaskCategory"
              :options="categoryOptions"
              optionLabel="name"
              optionValue="code"
              name="create-task-category"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="create-task-description">Task Description</label>
            <InputText
              type="text"
              v-model="createTaskDescription"
              name="create-task-description"
            ></InputText>
          </div>
          <div class="p-col-12" v-if="isManager == 'true'">
            <div class="p-grid p-mt-4">
              <div class="p-field p-col-6">
                <label for="company-task-switch" class="p-col-12 p-md-12"
                  >Company Task</label
                >
                <InputSwitch
                  v-model="companyTask"
                  name="company-task-switch"
                  class="p-col-2"
                />
              </div>
              <div class="p-field p-col-6" v-show="!companyTask">
                <label for="create-task-employees">Assign Employees</label>
                <MultiSelect
                  v-model="selectedEmployees"
                  :options="employeeNames"
                  optionLabel="label"
                  optionValue="value"
                  name="create-task-employees"
                />
              </div>
            </div>
          </div>

          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="sendCreateTask()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCreateModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, toRefs, onMounted, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const Calendar = defineAsyncComponent(() => import("primevue/calendar"));
//import Calendar from "primevue/calendar";
const InputSwitch = defineAsyncComponent(() => import("primevue/inputswitch"));
//import InputSwitch from "primevue/inputswitch";
export default {
  props: ["openCreateModal"],
  components: {
    Calendar,
    InputSwitch,
  },
  setup(props, context) {
    const store = useStore();
    const isManager = localStorage.getItem("isManager");
    const createTaskName = ref("");
    const employeeNames = ref([]);
    const selectedEmployees = ref([]);
    const openModal = toRefs(props).openCreateModal;
    const createTaskCategory = ref("");
    const createTaskDescription = ref("");
    const createTaskDueDate = ref("");
    const isWaiting = ref(false);
    const companyTask = ref(true);
    const minDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );

    const categoryOptions = [
      { name: "Security", code: "Security" },
      { name: "Safety", code: "Safety" },
      { name: "Compliance", code: "Compliance" },
      { name: "Administrative", code: "Administrative" },
      { name: "Regulatory", code: "Regulatory" },
      { name: "Other", code: "Other" },
    ];

    const sendCreateTask = async () => {
      isWaiting.value = true;
      if (isManager == "false") {
        const empName = store.getters.username;
        selectedEmployees.value.push(empName);
      }
      const createdTask = {
        "create-task-name": createTaskName.value,
        "create-task-category": createTaskCategory.value,
        "create-task-description": createTaskDescription.value,
        "create-task-dueDate": createTaskDueDate.value,
        "create-task-employees": selectedEmployees.value,
        "create-task-isPersonal": !companyTask.value,
        "create-task-custom": true,
      };
      store
        .dispatch("sendCreateTask", createdTask)
        .then(async () => {
          const newTasks = store.getters.getTasks;
          context.emit("task-created-successfully", newTasks);
          resetForm();
          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("task-creation-unsuccessful", error);
          console.error(error);
        });
    };
    const getEmployeeNames = async () => {
      isWaiting.value = true;
      if (isManager == "false") {
        companyTask.value = false;
      }
      await store.dispatch("getEmployeeNames").then(() => {
        const returnedNames = store.getters.getEmployeeNames;
        let nameObjects = [];
        returnedNames.forEach((name) => {
          const nameObj = {
            label: name,
            value: name,
          };
          nameObjects.push(nameObj);
        });
        employeeNames.value.push(...nameObjects);
      });
      isWaiting.value = false;
    };
    onMounted(getEmployeeNames);

    const resetForm = () => {
      createTaskName.value = "";
      createTaskDueDate.value = "";
      createTaskDescription.value = "";
      createTaskCategory.value = "";
      selectedEmployees.value = [];
    };

    const closeCreateModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    return {
      createTaskName,
      createTaskCategory,
      createTaskDescription,
      createTaskDueDate,
      sendCreateTask,
      categoryOptions,
      openModal,
      closeCreateModal,
      isWaiting,
      minDate,
      Calendar,
      InputSwitch,
      getEmployeeNames,
      employeeNames,
      selectedEmployees,
      companyTask,
      isManager,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  height: auto;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>