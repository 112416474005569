<template>
  <div class="container" v-if="applicationData != null">
    <DataTable
      :value="applicationData"
      responsiveLayout="scroll"
      editMode="row"
      datakey="id"
      v-model:editingRows="editingRows"
      @rowEditInit="onRowEditInit"
      @rowEditCancel="onRowEditCancel"
      @rowEditSave="onRowEditComplete"
      :paginator="true"
      :rows="6"
    >
      <template #empty>No Applications were found.</template>
      <Column field="name" header="Application Name">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]"
        /></template>
      </Column>
      <Column field="category" header="Category">
        <template #editor="slotProps">
          <Dropdown
            v-model="slotProps.data.category"
            :options="AppCategories"
            optionLabel="name"
            optionValue="name"
            placeholder="Select Category"
            :editable="true"
          />
        </template>
      </Column>
      <Column field="description" header="Description">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]"
        /></template>
      </Column>
      <Column field="ephi" header="ePHI" headerStyle="width:7%">
        <template #body="slotProps">
          <Checkbox
            v-model="slotProps.data.ephi"
            :binary="true"
            disabled="true"
          />
        </template>
        <template #editor="slotProps">
          <Checkbox v-model="slotProps.data.ephi" :binary="true" />
        </template>
      </Column>
      <Column field="pii" header="PII" headerStyle="width:6%">
        <template #body="slotProps">
          <Checkbox
            v-model="slotProps.data.pii"
            :binary="true"
            disabled="true"
          />
        </template>
        <template #editor="slotProps">
          <Checkbox v-model="slotProps.data.pii" :binary="true" />
        </template>
      </Column>
      <Column field="pci" header="PCI" headerStyle="width:6%">
        <template #body="slotProps">
          <Checkbox
            v-model="slotProps.data.pci"
            :binary="true"
            disabled="true"
          />
        </template>
        <template #editor="slotProps">
          <Checkbox v-model="slotProps.data.pci" :binary="true" />
        </template>
      </Column>
      <Column
        :rowEditor="true"
        headerStyle="width:6%"
        bodyStyle="text-align:center"
      >
      </Column>
      <Column style="width: 4rem">
        <template #body="slotProps" v-if="isManager">
          <i
            class="pi pi-trash clickable"
            @click="deleteApplication(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <div class="p-col-3">
      <Button
        label="Add Application"
        icon="pi pi-plus p-button-lg"
        @click="addApplication"
      />
    </div>
  </div>
</template>
<script>
import Column from "primevue/column";
export default {
  components: {
    Column,
  },
  created() {
    this.originalRows = {};
  },

  methods: {
    addApplication() {
      this.$emit("add-application-clicked");
    },
    deleteApplication(data) {
      this.$emit("delete-application-clicked", data);
    },
    onRowEditInit(event) {
      this.originalRows[event.index] = { ...this.applications[event.index] };
    },
    onRowEditCancel() {
      return;
    },
    async onRowEditComplete(event) {
      this.$store.dispatch("editApplications", event.data).then(() => {
        this.$emit("application-edited", event);
      });
    },
  },
  props: ["applications"],
  watch: {
    applications(newVal) {
      this.applicationData = newVal;
    },
  },
  data() {
    return {
      originalRows: {},
      editingRows: [],
      isManager: localStorage.getItem("isManager") === "true" ? true : false,
      AppCategories: [
        { name: "E-mail" },
        { name: "Windows" },
        { name: "MacOS" },
        { name: "EMR/EHR" },
        { name: "Office" },
        { name: "File Server" },
      ],
      //Enabling Two way binding with a local variable
      applicationData: this.applications,
    };
  },
};
</script>

<style scoped></style>
