<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCreateModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="upload-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="name">Document Name</label>
            <InputText type="text" v-model="documentName" name="name" />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="type">File Type</label>
            <Dropdown
              v-model="documentType"
              :options="documentTypeOptions"
              optionLabel="name"
              optionValue="name"
              placeholder="Select Type"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="description">File Description</label>
            <InputText
              type="text"
              v-model="documentDescription"
              name="description"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="filetype">Upload Document</label>
            <FileUpload
              name="document"
              accept=".csv,text/plain,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
              :customUpload="true"
              :auto="true"
              :clear="cancelUpload"
              :showUploadButton="false"
              :maxFileSize="10485760"
              :fileLimit="1"
              @uploader="documentUploader"
            >
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="UploadDocument()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCreateModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const FileUpload = defineAsyncComponent(() => import("primevue/fileupload"));
export default {
  props: ["openUploadModal"],
  components: {
    FileUpload,
  },
  setup(props, context) {
    const store = useStore();
    const documentName = ref("");
    const documentType = ref("");
    const documentDescription = ref("");
    const documentTypeOptions = [
      { name: "Security Risk Assessment" },
      { name: "Fire Drill Documentation" },
      { name: "Emergency Response Plan" },
      { name: "Business Continuity/Disaster Recovery" },
      { name: "Employee Handbook" },
      { name: "Policy Documentation" },
      { name: "Procedure Documentation" },
      { name: "Security Documentation" },
      { name: "Training Documentation" },
    ];
    const openModal = computed(() => props.openUploadModal);
    const uploadedFile = ref(""); //This is where we will store the file when the user clicks Upload.
    const isWaiting = ref(false);
    const UploadDocument = async () => {
      isWaiting.value = true;
      let formData = new FormData();
      const uploadedDocument = {
        name: documentName.value,
        type: documentType.value,
        description: documentDescription.value,
        document: uploadedFile.value[0],
      };
      formData.append("name", uploadedDocument.name);
      formData.append("type", uploadedDocument.type);
      formData.append("description", uploadedDocument.description);
      formData.append(
        "document",
        uploadedDocument.document,
        uploadedDocument.document.name
      );

      store
        .dispatch("uploadDocument", formData)
        .then(() => {
          context.emit("document-uploaded-successfully");
          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("document-uploaded-unsuccessful", error);
          console.error(error);
        });
      context.emit("close-modal", uploadedDocument);
      closeCreateModal();
    };

    //This is the custom function for when the user clicks Upload. We are simply storing the file (event.files) in a variable called uploadedFile.
    const documentUploader = (event) => {
      uploadedFile.value = event.files;
    };
    //This sets the value of the uploadedFile variable to null when the user clicks cancel.
    const cancelUpload = () => {
      uploadedFile.value = null;
    };

    const resetForm = () => {
      documentName.value = "";
      documentType.value = "";
      documentDescription.value = "";
      uploadedFile.value = null;
    };

    const closeCreateModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    return {
      documentName,
      documentType,
      documentDescription,
      documentTypeOptions,
      uploadedFile,
      documentUploader,
      UploadDocument,
      openModal,
      closeCreateModal,
      isWaiting,
      FileUpload,
      cancelUpload,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 15vh;
  width: 50rem;
  height: 33rem;
  left: calc(50% - 25rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>