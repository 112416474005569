import devAPI from "../../../api/devAPI.js";

export default {
  async getEmployeeNames(context) {
    const token = localStorage.getItem("token");
    return devAPI
      .get("employees", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Employee-Names",
        },
      })
      .then(async (response) => {
        await context.commit("setEmployeeNames", response.data.data);
      });
  },

  async sendEditManagerTask(context, payload) {
    let dateFromForm = new Date(payload["edit-task-dueDate"]);
    const dateToSend =
      dateFromForm.getMonth() +
      1 +
      "/" +
      dateFromForm.getDate() +
      "/" +
      dateFromForm.getFullYear();
    payload["edit-task-dueDate"] = dateToSend;
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    let action = payload["edit-task-isPersonal"]
      ? "Edit-Personal-Task"
      : "Edit-Company-Task";
    return devAPI
      .post("/tasks", dataFromForm, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then((response) => {
        const responseData = response.data.data;
        context.commit("editManagerTask", responseData);
        context.dispatch("getTasksForManagers");
      });
  },

  async getPermissions(context) {
    const token = localStorage.getItem("token");
    return devAPI
      .get("permissions", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Permissions",
        },
      })
      .then(async (response) => {
        await context.commit("setPermissions", response.data.data);
      });
  },

  editRole(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI
      .post("roles", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Edit-Role",
        },
      })
      .then(async (response) => {
        await context.commit("setRoles", response.data.data);
      });
  },

  getRoles(context) {
    const token = localStorage.getItem("token");
    return devAPI
      .get("roles", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Roles",
        },
      })
      .then((response) => {
        context.commit("setRoles", response.data.data);
        return response;
      });
  },

  createRole(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI
      .post("roles", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Create-Role",
        },
      })
      .then((response) => {
        const returnedRoles = response.data.data;
        context.commit("addRole", returnedRoles);
      });
  },

  async addRole(context, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI
      .post("roles", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Add-Employee-Role", //This is required for the backend to route the request properly.
        },
      })
      .then(async () => {
        //this sets the updated employees
        await context.dispatch("getEmployees");
      });
  },

  async deleteRole(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI
      .post("roles", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Delete-Role", //This is required for the backend to route the request properly.
        },
      })
      .then(async () => {
        context.dispatch("getRoles");
      });
  },

  async getTasksForManagers(context) {
    const token = localStorage.getItem("token");
    return devAPI
      .get("tasks", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Tasks-Manager",
        },
      })
      .then(async (response) => {
        const responseData = response.data;
        let allTasks = [];
        const companyTasks = responseData?.data?.companyTasks;
        const personalTasks = responseData?.data?.personalTasks;
        if (companyTasks) {
          for (let task of companyTasks) {
            task.completedBy = task.completedBy ?? [];
            task.dueDate = new Date(task.dueDate).toLocaleString("en-us", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            allTasks.push(task);
          }
        }
        if (personalTasks) {
          for (let task of personalTasks) {
            task.completedBy = task.completedBy ?? [];
            task.dueDate = new Date(task.dueDate).toLocaleString("en-us", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            allTasks.push(task);
          }
        }
        await context.commit("setManagerTasks", allTasks);
      });
  },

  async setSelectedTaskRow(context, payload) {
    context.commit("setSelectedTaskRow", payload);
  },

  async setOriginalTaskRow(context, payload) {
    context.commit("setOriginalTaskRow", payload);
  },

  setOriginalRoleRow(context, payload) {
    context.commit("setOriginalRoleRow", payload);
  },
};
