<template>
  <div class="container">
    <router-view
      :formData="formObject"
      @prev-page="prevPage($event)"
      @next-page="nextPage($event)"
      @complete="createRole"
      @delete-role-clicked="deleteRole"
      @role-edited="roleEditSuccess"
    ></router-view>
  </div>
  <Toast />
  <ConfirmDialog />
</template>

<script>
import { useStore } from "vuex";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";

export default {
  components: {
    ConfirmDialog,
    Toast,
  },
  data() {
    return {
      createRoleShow: false,
      formObject: {},
      selectedRole: null,
      Roles: this.$store.getters.getRoles,
    };
  },
  methods: {
    editRole(role) {
      this.selectedRole = role;
      this.editRoleShow = true;
    },

    deleteRole(deletedRow) {
      this.$confirm.require({
        message: "Are you sure you want to delete this Role?",
        header: "Delete confirmation",
        accept: () => {
          this.$store
            .dispatch("deleteRole", deletedRow)
            .then(async () => {
              this.Roles = await this.$store.getters.getRoles;
              this.$toast.add({
                severity: "success",
                summary: "Role deleted.",
                life: 5000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Role delete failed",
                life: 5000,
              });
            });
        },
      });
    },

    openCreateRole() {
      this.createRoleShow = true;
    },

    closeCreateRole() {
      this.createRoleShow = false;
    },

    roleEditSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Role edited successfully.",
        life: 4000,
      });
    },

    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }
      this.$router.push(this.stepItems[event.pageIndex + 1].to);
    },

    prevPage(event) {
      this.$router.push(this.stepItems[event.pageIndex - 1].to);
    },

    createRole() {
      const store = useStore();
      const createdRole = {
        name: this.formObject.name,
        description: this.formObject.description,
        applications: this.formObject.applications,
        employees: this.formObject.employees,
        isManager: this.formObject.isManager,
      };
      store
        .dispatch("createRole", createdRole)
        .then(() => {
          this.Roles = this.$store.getters.getRoles;
          this.$toast.add({
            severity: "success",
            summary: "Role Created Successfully",
            life: 5000,
          });
        })
        .catch((error) => {
          this.$toast.add({
            severity: "danger",
            summary: "Role Creation Failed",
            life: 5000,
          });
          console.error(error);
        });
      this.closeCreateRole();
    },
  },
};
</script>