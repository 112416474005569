import devAPI from "../../../api/devAPI.js";

export default {
  async uploadDocument(context, payload) {
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    const action = "Create-UserFile";
    return devAPI
      .post("userfiles", dataFromForm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then(async (response) => {
        const documentResponse = response.data.data;
        await context.commit("setDocuments", documentResponse);
      });
  },

  async getDocuments(context) {
    const token = localStorage.getItem("token");
    return devAPI
      .get("userfiles", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Documents",
        },
      })
      .then(async (response) => {
        await context.commit("setDocuments", response.data.data);
      });
  },

  async downloadFile(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI.post("userfiles", payload, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
        Action: "Get-UserFile",
      },
    });
  },

  async deleteDocument(context, payload) {
    const token = localStorage.getItem("token");
    const action = "Delete-Document";
    return devAPI.post("userfiles", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: action,
      },
    });
  },
};
