<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCheckInModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="add-workLog-onSite">Worker On Site</label>
            <InputText
              type="text"
              v-model="createWorkLogOnSite"
              name="add-workLog-onSite"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="add-workLog-workDescription">Work Description</label>
            <Textarea
              v-model="createWorkLogWorkDescription"
              :autoResize="true"
              rows="5"
              cols="30"
              name="add-workLog-workDescription"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="checkInVendor()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCheckInModal()"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed } from "vue";

export default {
  props: ["openCheckInModal", "parentVendor"],
  setup(props, context) {
    //const store = useStore();
    const createWorkLogOnSite = ref("");
    const createWorkLogWorkDescription = ref("");
    const selectedVendor = computed(() => props.parentVendor);
    const currentTime = Date.now();
    const today = new Date(currentTime);
    const openModal = computed(() => props.openCheckInModal);
    const isWaiting = ref(false);

    const checkInVendor = async () => {
      isWaiting.value = true;
      const checkedInVendor = {
        workLog: {
          onSite: createWorkLogOnSite.value,
          workDescription: createWorkLogWorkDescription.value,
          checkIn: today.toLocaleString("en-us", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "America/Los_Angeles",
          }), //get current time
          checkOut: false,
          checkInUser: localStorage.getItem("username"),
        },
      };
      context.emit("vendor-check-in", checkedInVendor);
      closeCheckInModal();
    };

    const resetForm = () => {
      createWorkLogOnSite.value = "";
    };

    const closeCheckInModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    return {
      createWorkLogOnSite,
      createWorkLogWorkDescription,
      checkInVendor,
      openModal,
      currentTime,
      closeCheckInModal,
      isWaiting,
      selectedVendor,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  height: 20rem;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>