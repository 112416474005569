<template>
  <div class="container">
    <div class="steps">
      <div class="card">
        <Steps :model="stepItems" :readonly="true" />
      </div>
      <router-view
        v-slot="{ Component }"
        :formData="formObject"
        :roleBeingAdded="addingRole"
        @prev-page="prevPage($event)"
        @next-page="nextPage($event)"
        @complete="createRole"
      >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import Steps from "primevue/steps";
export default {
  data() {
    return {
      addingRole: false,
      stepItems: [
        {
          label: "Role Details",
          to: "/manager/roles/addrole",
        },
        {
          label: "Role Employees",
          to: "/manager/roles/addrole/employees",
        },
        {
          label: "Role Applications",
          to: "/manager/roles/addRole/applications",
        },
      ],
      formObject: {},
    };
  },
  methods: {
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }
      this.$router.push(this.stepItems[event.pageIndex + 1].to);
    },

    prevPage(event) {
      this.$router.push(this.stepItems[event.pageIndex - 1].to);
    },
    createRole(event) {
      this.addingRole = true;
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }
      const createdRole = {
        name: this.formObject.name,
        description: this.formObject.description,
        applications: this.formObject.applications,
        employees: this.formObject.employees,
        isManager: this.formObject.isManager,
      };
      this.$store
        .dispatch("createRole", createdRole)
        .then(() => {
          this.Roles = this.$store.getters.getRoles;
          this.$toast.add({
            severity: "success",
            summary: "Role Created Successfully",
            life: 5000,
          });
          this.addingRole = false;
          this.$router.push("/manager/roles");
        })
        .catch((error) => {
          this.$toast.add({
            severity: "danger",
            summary: "Role Creation Failed",
            life: 5000,
          });
          this.addingRole = false;
          this.$router.push("/manager/roles");
          console.error(error);
        });
    },
  },
  components: {
    Steps,
  },
};
</script>