<template>
  <div class="createApplicationModal">
    <AddApplicationModal
      @application-created-successfully="applicationCreateSuccess"
      @application-creation-unsuccessful="applicationCreateFailed"
      @close-modal="closeCreateModal"
      :openCreateModal="createModalShow"
    />
  </div>
  <ApplicationsDataTable
    :applications="applications"
    @application-edited="applicationEditSuccess"
    @delete-application-clicked="confirmDeleteApplication"
    @add-application-clicked="openCreateApplicationModal"
  />
  <Toast />
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import ApplicationsDataTable from "../../components/applications/ApplicationsDataTable.vue";
import AddApplicationModal from "../../components/applications/AddApplicationModal.vue";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  components: {
    ApplicationsDataTable,
    AddApplicationModal,
    ConfirmDialog,
  },
  data() {
    return {
      applications: [],
      createModalShow: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("getApplications").then(() => {
      const applicationsStore = this.$store.getters.getApplications
        .applications;
      this.applications.push(...applicationsStore);
    });
  },
  methods: {
    applicationCreateSuccess(newApp) {
      this.$toast.add({
        severity: "success",
        summary: "Application Created Successfully.",
        life: 5000,
      });
      this.applications.push(newApp);
    },

    applicationEditSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Application Edited Successfully.",
        life: 5000,
      });
    },

    openCreateApplicationModal() {
      this.createModalShow = true;
    },

    closeCreateModal() {
      this.createModalShow = false;
    },

    applicationCreateFailed() {
      this.$toast.add({
        severity: "fail",
        summary: "Application Creation failed.",
        life: 5000,
      });
    },

    async confirmDeleteApplication(deletedRow) {
      this.$confirm.require({
        message: "Are you sure you want to delete this Application?",
        header: "Delete confirmation",
        accept: () => {
          this.$store.dispatch("deleteApplication", deletedRow).then(() => {
            let newApplications = this.applications.filter(
              (found) => found.key != deletedRow.key
            );
            this.applications = [...newApplications];
            this.$toast.add({
              severity: "success",
              summary: "Application deleted.",
              life: 5000,
            });
          });
        },
      });
    },
  },
};
</script>

<style scoped>
</style>