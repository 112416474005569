export default {
  /**
   * Sets the vendors into the Vuex state. The vendors are retrieved by actions.js and then passed into the mutation for storage.
   * @param {Object} state The state object for the store. See {@link https://next.vuex.vuejs.org/guide/state.html} for more information.
   * @param {*} payload This has an array of vendors retrieved from the backend. This is called by an action in actions.js in this module.
   */
  setVendors(state, payload) {
    state.vendors = payload.vendors;
  },

  updateVendors(state, payload) {
    state.vendors = payload;
  },

  addVendor(state, payload) {
    state.vendors.push(payload);
  },

  deleteVendor(state, payload) {
    //This receives the deleted row as a payload and filters out the array to return all rows except for the deleted row.
    state.vendors = state.vendors.filter((val) => val.key !== payload.key);
  },

  vendorEditRow(state, payload) {
    Object.assign(state.vendorEditRow, payload);
  },

  worklogEditRow(state, payload) {
    Object.assign(state.worklogEditRow, payload);
  },

  addWorkLog(state, payload) {
    let vendorRowIndex = state.vendors.findIndex(
      (found) => found.key === payload.key
    );
    if (state.vendors[vendorRowIndex].workLog == null) {
      state.vendors[vendorRowIndex].workLog = new Array();
      state.vendors[vendorRowIndex].workLog.push(payload);
    } else {
      state.vendors[vendorRowIndex].workLog.push(payload);
    }
  },
};
