<template>
  <div>
    <DataTable
      :value="documentData"
      datakey="id"
      :paginator="true"
      :rows="6"
      :loading="loading"
    >
      <template #empty>No Documents were found.</template>
      <Column field="name" header="Document Name"> </Column>
      <Column field="category" header="Document Type">
        <template #body="slotProps">
          <span>{{ returnCategory(slotProps.data.category) }}</span>
        </template>
      </Column>
      <Column field="description" header="Description"></Column>
      <Column
        columnKey="{{'document-menu-' + key}}"
        headerStyle="width: 3rem; text-align:center;"
        bodyStyle="text-align:center; overflow: visible;"
      >
        <template #body="slotProps">
          <i
            class="pi pi-trash document-menu user-icon clickable"
            @click="deleteDocument($event, slotProps.data)"
          />
        </template>
      </Column>
      <Column
        columnKey="{{'download-menu' + key}}"
        headerStyle="width: 3rem; text-align:center;"
        bodyStyle="text-align:center; overflow:visible;"
      >
        <template #body="slotProps">
          <i
            class="pi pi-download user-icon clickable"
            @click="downloadFile($event, slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <div class="p-col-3">
      <Button
        label="Upload Document"
        icon="pi pi-plus p-button-lg"
        @click="uploadDocument()"
      />
    </div>
  </div>
</template>
<script>
import Column from "primevue/column";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    Column,
  },
  props: ["documents"],
  setup(props, context) {
    const store = useStore();
    const documentData = computed({
      get: () => store.getters.getDocuments,
    });

    function deleteDocument(event, selectedDocument) {
      context.emit("delete-document", selectedDocument);
    }

    function downloadFile(event, data) {
      store.dispatch("downloadFile", data).then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const fileConfig = JSON.parse(response.config.data);
        const fileName = fileConfig.name + "." + fileConfig.extension;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    }

    function returnCategory(category) {
      switch (category) {
        case "bcdr":
          return "Business Continuity/Disaster Recovery";
        case "firedrill":
          return "Fire Drill Records";
        case "erp":
          return "Emergency Response Plan";
        case "sra":
          return "Security Risk Assessment";
        case "handbook":
          return "Employee Handbook";
        case "policy":
          return "Policy Documentation";
        case "procedure":
          return "Procedure Documentation";
        case "security":
          return "Security Documentation";
        case "training":
          return "Training Documentation";
        case "baa":
          return "Business Associate Agreement";
      }
    }

    function uploadDocument() {
      context.emit("upload-document");
    }

    return {
      uploadDocument,
      documentData,
      deleteDocument,
      returnCategory,
      downloadFile,
    };
  },
};
</script>

<style scoped>
.workLogs-subtable {
  padding: 1rem;
}
</style>