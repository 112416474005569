export default {
  /**
   *
   * @param {Object} state The state for this module. See {@link https://next.vuex.vuejs.org/guide/getters.html} for more information.
   */
  getEmployees(state) {
    return state.employees;
  },

  getEmployeeCount(state) {
    return state.employees.length;
  },
  getEmployeeNames(state) {
    return state.employeeNames;
  },
};
