export default {
  companySecurityScore(state) {
    return state.companyScore;
  },
  personalSecurityScore(state) {
    return state.personalScore;
  },
  companyTasks(state) {
    return state.companyTasks;
  },
  personalTasks(state) {
    if (!state.personalTasks) return;
    const assignedTasks = state.personalTasks.filter((task) => {
      let assignedEmployees = task.employees;
      const userName = localStorage.getItem("displayName");
      return assignedEmployees.includes(userName);
    });
    return assignedTasks;
  },
  getTasks(_, getters) {
    const companyTasks = getters.companyTasks ?? [];
    const personalTasks = getters.personalTasks ?? [];
    return [...companyTasks, ...personalTasks];
  },
  showEditModal(state) {
    return state.showEditModal;
  },
  getOverdue(state) {
    return state.tasksOverdue;
  },
  selectedRow(state) {
    return {
      taskName: state.taskName,
      taskDescription: state.taskDescription,
      taskCategory: state.taskCategory,
      taskDueDate: state.taskDueDate,
      taskKey: state.taskKey,
      taskPostID: state.taskPostID,
      taskRowNum: state.rowNum,
    };
  },
};
