import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";
export default {
  state() {
    return {
      applicationNames: [],
      employeeNames: [],
      permissions: [],
      roles: [],
      originalTaskRow: null,
      originalRoleRow: null,
      managerTasks: [],
      managerTasksOverdue: 0,
      selectedTaskRow: null,
    };
  },
  getters,
  actions,
  mutations,
};
