import { createRouter, createWebHistory } from "vue-router";
import UserLogin from "./pages/auth/UserAuthPage.vue";
import Dashboard from "./pages/dashboard/Dashboard.vue";
import AssetsPage from "./pages/assets/AssetsPage.vue";
import VendorsPage from "./pages/vendors/VendorsPage.vue";
import ApplicationsPage from "./pages/applications/ApplicationsPage.vue";
import EmployeesPage from "./pages/employees/EmployeesPage.vue";
import DocumentsPage from "./pages/documents/DocumentsPage.vue";
import TrainingPage from "./pages/training/TrainingPage.vue";
import AdminPage from "./pages/admin/AdminPage.vue";
import AccountPage from "./pages/account/AccountPage.vue";
import ManagerPage from "./pages/manager/ManagerPage.vue";
import RolePage from "./pages/roles/RolePage.vue";
//import ReportsPage from "./pages/reports/ReportsPage.vue";
import AddRoleModal from "./components/manager/roles/AddRoleModal.vue";
import RoleDetails from "./components/manager/roles/AddRoleDetails.vue";
import RoleDataTable from "./components/manager/roles/RoleDataTable.vue";
import AddApplications from "./components/manager/roles/AddApplications.vue";
import AddEmployees from "./components/manager/roles/AddEmployees.vue";
import TasksPage from "./pages/tasks/TasksPage.vue";
import { nextTick } from "vue";
//import TasksPage from "./pages/tasks/TasksPage.vue";
/*import CompanyPage from "./pages/companies/CompanyPage.vue"; */

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const defaultTitle = "Zekteck Client Portal";

/**
 * Defines the router object which handles the navigation for the site.
 */
const router = createRouter({
  history: createWebHistory(), //This allows for the back and forward buttons to work on the browser
  routes: [
    {
      path: "/assets",
      component: AssetsPage,
      name: "assets",
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: false,
      },
    },
    {
      path: "/applications",
      component: ApplicationsPage,
      name: "applications",
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: true,
      },
    },
    {
      path: "/employees",
      component: EmployeesPage,
      name: "employees",
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: true,
      },
    },
    {
      path: "/userfiles",
      component: DocumentsPage,
      name: "documents",
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: false,
      },
    },
    {
      path: "/vendors",
      name: "vendors",
      component: VendorsPage,
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: false,
      },
    },
    {
      path: "/training",
      component: TrainingPage,
      name: "training",
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: false,
      },
    },
    {
      path: "/account",
      name: "account",
      component: AccountPage,
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: false,
      },
    },
    {
      path: "/admin",
      name: "admin",
      component: AdminPage,
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: true,
      },
    },
    {
      path: "/",
      name: "home",
      component: Dashboard,
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: false,
      },
    },
    {
      path: "/login",
      component: UserLogin,
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
        needsManager: false,
      },
    },
    {
      path: "/manager",
      component: ManagerPage,
      name: "managers",
      meta: {
        title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
      },
      children: [
        //{ path: "/company", component: CompanyPage },
        {
          path: "",
          component: EmployeesPage,
          name: "managers | Home",
          meta: {
            title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
          },
        },
        {
          path: "employees",
          component: EmployeesPage,
          name: "managers | Employees",
          meta: {
            title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
          },
        },
        {
          path: "roles",
          component: RolePage,
          name: "managers | Roles",
          meta: {
            title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
          },
          children: [
            {
              path: "",
              component: RoleDataTable,
              name: "managers | Roles",
            },
            {
              path: "addrole",
              component: AddRoleModal,
              name: "managers | Add Role",
              meta: {
                title: (route) => `${capitalize(route.name)} - ${defaultTitle}`,
              },
              children: [
                {
                  path: "",
                  component: RoleDetails,
                  name: "managers | Role Details",
                  meta: {
                    title: (route) =>
                      `${capitalize(route.name)} - ${defaultTitle}`,
                  },
                },
                {
                  path: "employees",
                  component: AddEmployees,
                  name: "managers | Add Employees",
                  meta: {
                    title: (route) =>
                      `${capitalize(route.name)} - ${defaultTitle}`,
                  },
                },
                {
                  path: "applications",
                  component: AddApplications,
                  name: "managers | Add Applications",
                  meta: {
                    title: (route) =>
                      `${capitalize(route.name)} - ${defaultTitle}`,
                  },
                },
              ],
            },
          ],
        },
        { path: "tasks", component: TasksPage, name: "managers | Tasks" },
        /*{
          path: "reports",
          component: ReportsPage,
          name: "managers | Reports",
        },*/
      ],
    },
  ],
});

//Navigation Guards to ensure the user can't access routes that they aren't allowed to access.
router.beforeEach((to, from, next) => {
  if (to.meta.needsManager) {
    const isManager = localStorage.getItem("isManager");
    if (isManager === "false") {
      next({ name: "home" });
    } else if (isManager === "true") {
      next();
    }
  }
  const loggedOut = localStorage.getItem("loggedOut");
  const token = localStorage.getItem("token");
  if (to.path !== "/login") {
    if (token == null || loggedOut === "true") {
      next({ path: "/login" });
    } else {
      next();
    }
  } else next();
});

router.afterEach((to) => {
  nextTick(() => {
    document.title =
      typeof to.meta.title === "function" ? to.meta.title(to) : to.meta.title;
  });
});

export default router;
