<template>
  <div class="container" v-if="tasks != null">
    <Toast />
    <DataTable
      :value="tasks"
      responsiveLayout="scroll"
      datakey="id"
      :paginator="true"
      :rows="6"
    >
      <template #empty>No Tasks were found.</template>
      <Column
        headerStyle="width: 8rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
        columnKey="{{'task-badge' + key}}"
        field="isPersonal"
        :sortable="true"
      >
        <template #body="slotProps">
          <Tag
            value="Company"
            icon="pi pi-briefcase"
            class="company-task-tag"
            v-if="!slotProps.data.isPersonal"
          ></Tag>
          <Tag
            value="Personal"
            icon="pi pi-user"
            v-if="slotProps.data.isPersonal"
            class="personal-task-tag"
          ></Tag>
        </template>
      </Column>
      <Column field="name" header="Name">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.name" />
        </template>
      </Column>
      <Column field="description" header="Description">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.description" />
        </template>
      </Column>
      <Column
        field="dueDate"
        header="Due Date"
        sortable="true"
        headerStyle="text-align: center"
        bodyStyle="text-align: center"
      >
        <template #editor="slotProps">
          <Calendar
            v-model="slotProps.data.dueDate"
            :minDate="minDate"
            :showIcon="true"
            name="edit-task-dueDate"
          />
        </template>
      </Column>
      <Column field="category" header="Category" sortable="true">
        <template #editor="slotProps">
          <Dropdown
            v-model="slotProps.data.category"
            :options="categotyOptions"
            optionLabel="name"
            optionValue="name"
            placeholder="Select Category"
            :editable="true"
          />
        </template>
      </Column>
      <Column field="employees" header="Employees" sortable="true">
        <template #body="slotProps">
          <span>{{
            slotProps.data.isPersonal
              ? truncateEmployees(slotProps.data.employees, 2)
              : "All Employees"
          }}</span>
        </template>
        <template #editor="slotProps">
          <MultiSelect
            v-model="slotProps.data.employees"
            :options="employeeNames"
            optionLabel="label"
            optionValue="value"
            name="edit-task-employees"
          />
        </template>
      </Column>
      <Column field="completedBy" header="Completed By" sortable="true">
        <template #body="slotProps">
          <span>{{
            slotProps.data.completedBy != null
              ? truncateEmployees(slotProps.data.completedBy, 2)
              : ""
          }}</span>
        </template>
      </Column>
      <Column headerStyle="width:7rem" bodyStyle="text-align:center">
        <template #body="slotProps">
          <i
            class="pi pi-pencil task-menu"
            @click="showTaskEditModal(slotProps.data)"
            v-if="slotProps.data.custom"
          />
        </template>
      </Column>
      <Column
        columnKey="{{'task-delete-' + key}}"
        headerStyle="width: 4rem; text-align: center;"
        bodyStyle="text-align:center; overflow: visible"
      >
        <!-- taskMenuClick function passes back the current row's data. The function then stores this in the ClickedRow variable. -->
        <template #body="slotProps">
          <i
            class="pi pi-trash task-menu"
            @click="deleteTask(slotProps.data)"
            v-if="showDelete(slotProps.data)"
          />
          <i
            class="pi pi-trash task-menu-disabled"
            disabled="true"
            v-if="!slotProps.data.custom"
            v-tooltip="'This is a system-generated task'"
          />
        </template>
      </Column>
    </DataTable>
    <ConfirmDialog></ConfirmDialog>
    <div class="p-col-3">
      <Button label="Add Task" icon="pi pi-plus p-button-lg" @click="addTask" />
    </div>
  </div>
</template>
<script>
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Tag from "primevue/tag";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    Column,
    Tag,
    Calendar,
  },
  setup(props, context) {
    const store = useStore();
    const taskMenu = ref(null);
    const numOverdue = computed(() => store.getters.getOverdue);
    const tasks = computed(() => store.getters.getManagerTasks);
    const employeeNames = ref([]);
    const editingRows = ref([]);
    const selectedEmployees = ref([]);
    const isWaiting = ref(false);
    const companyTask = ref(true);
    const isManager = localStorage.getItem("isManager");
    const clickedRow = reactive({});
    const confirm = useConfirm();
    const toast = useToast();

    const categoryOptions = [
      { name: "Security", code: "Security" },
      { name: "Safety", code: "Safety" },
      { name: "Compliance", code: "Compliance" },
      { name: "Administrative", code: "Administrative" },
      { name: "Regulatory", code: "Regulatory" },
      { name: "Other", code: "Other" },
    ];
    const minDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    /* const menu = reactive({
      menuItems: [
        {
          label: "Edit Task",
          icon: "pi pi-pencil",
          command: () => {
            context.emit("open-edit-modal", clickedRow);
          },
        },
        {
          label: "Delete Task",
          icon: "pi pi-trash",
          command: () => {
            context.emit("delete-task", clickedRow);
          },
        },
      ],
    }); */
    function taskMenuClick(event, taskRow) {
      Object.assign(clickedRow, taskRow);
      taskMenu.value.toggle(event);
    }

    function truncateEmployees(str, n) {
      if (!str) {
        return "None";
      }
      if (str.length <= n) {
        return str.join(", ");
      } else {
        let returnArray = [];
        const additionalEmployees = str.length - n;
        for (let i = 0; i <= n; i++) {
          returnArray.push(str[i]);
        }
        let returnString =
          returnArray.join(", ") + " + " + additionalEmployees + " more";
        return returnString;
      }
    }

    function showDelete(row) {
      const isManager = localStorage.getItem("isManager");
      const isPersonal = row.isPersonal;
      const isCustom = row.custom;
      if ((isManager || isPersonal) && isCustom) {
        return true;
      } else return false;
    }

    function deleteTask(row) {
      confirm.require({
        message: "Are you sure you want to delete this task?",
        header: "Delete confirmation",
        accept: () => {
          confirm.close();
          store.dispatch("deleteTask", row).then(() => {
            toast.add({
              severity: "success",
              summary: "Task deleted.",
              group: "dashboardBody",
              life: 5000,
            });
          });
        },
      });
    }

    function rowStyling(data) {
      const dueDate = Date.parse(data.dueDate + " 23:59:59 GMT -08:00");
      const today = Date.now();
      if (dueDate < today) {
        return "overdue-task";
      } else return null;
    }

    function checkDate(dueDate) {
      const today = new Date();
      const dateDiff = Date.parse(dueDate + " 23:59:59 GMT -08:00") - today;
      if (dateDiff < 86400000) {
        return '<p style="color:red; font-weight:bold">' + dueDate + "</p>";
      } else {
        return dueDate;
      }
    }

    const getEmployeeNames = async () => {
      isWaiting.value = true;
      if (isManager == "false") {
        companyTask.value = false;
      }
      await store.dispatch("getEmployeeNames").then(async () => {
        const returnedNames = await store.getters.getEmployeeNames;
        let nameObjects = [];
        returnedNames.forEach((name) => {
          const nameObj = {
            label: name,
            value: name,
          };
          nameObjects.push(nameObj);
        });
        employeeNames.value.push(...nameObjects);
      });
      isWaiting.value = false;
    };
    onMounted(getEmployeeNames);

    function showTaskEditModal(task) {
      context.emit("open-edit-modal", task);
    }

    function addTask() {
      context.emit("add-task-clicked");
    }

    function taskMenuMouseOut(event) {
      taskMenu.value.toggle(event);
    }
    return {
      taskMenu,
      taskMenuClick,
      taskMenuMouseOut,
      tasks,
      addTask,
      checkDate,
      numOverdue,
      rowStyling,
      showDelete,
      getEmployeeNames,
      employeeNames,
      minDate,
      categoryOptions,
      selectedEmployees,
      editingRows,
      showTaskEditModal,
      truncateEmployees,
      useConfirm,
      deleteTask,
    };
  },
};
</script>

<style scoped>
.company-task-tag {
  font-size: 0.6rem;
  background-color: #f37b21;
}

.personal-task-tag {
  font-size: 0.6rem;
  background-color: #3056ec;
}

.task-menu {
  cursor: pointer;
}

.task-menu-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
</style>
