export default {
  /**
   *
   * @param {Object} state The state for this module. See {@link https://next.vuex.vuejs.org/guide/getters.html} for more information.
   */
  getVendors(state) {
    //We are simply returning the vendors in the store's state.
    //See index.js in this module to view the state.
    return state.vendors;
  },
  getOriginalVendorRow(state) {
    return state.vendorEditRow;
  },
};
