export default {
  /**
   * Sets the application into the Vuex state. The application are retrieved by actions.js and then passed into the mutation for storage.
   * @param {Object} state The state object for the store. See {@link https://next.vuex.vuejs.org/guide/state.html} for more information.
   * @param {*} payload This has an array of application retrieved from the backend. This is called by an action in actions.js in this module.
   */
  setApplications(state, payload) {
    state.applications = payload;
  },

  setApplicationNames(state, payload) {
    state.applicationNames = payload;
  },
};
