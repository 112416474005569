<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCreateModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
          v-if="canAddEmployees"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="employee_first_name">Employee First Name</label>
            <InputText
              type="text"
              v-model="createEmployeeFirstName"
              name="employee_first_name"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="employee_last_name">Employee Last Name</label>
            <InputText
              type="text"
              v-model="createEmployeeLastName"
              name="employee_last_name"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="employee_position">Position</label>
            <InputText
              type="text"
              v-model="createEmployePosition"
              name="employee_position"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="employee_email">Email</label>
            <InputText
              type="text"
              v-model="createEmployeeInfo"
              name="employee_email"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="sendCreateEmployee()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCreateModal"
            />
          </div>
        </form>
        <Card v-if="!canAddEmployees">
          <template #title> No Available Licenses </template>
          <template #content>
            <p>
              It looks like you don't have any available licenses to add another
              employee. You'll need to purchase additional licenses before
              adding more employees.
            </p>
            <p>
              Please note: only account owners can purchase additional licenses.
              If you are the account owner, please click
              <a href="https://zekteck.com/shop/my-account/" target="_blank"
                >here</a
              >
              to purchase additional licenses.
            </p>
            <p>
              If this is an error, please contact us at
              <a href="mailto:support@zekteck.com">support@zekteck.com</a>
            </p>
          </template>
        </Card>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  props: ["openCreateModal", "canAddEmployees"],
  setup(props, context) {
    const store = useStore();
    const createEmployeeFirstName = ref("");
    const createEmployeeLastName = ref("");
    const createEmployePosition = ref("");
    const createEmployeeInfo = ref("");
    const openModal = computed(() => props.openCreateModal);
    const isWaiting = ref(false);

    const sendCreateEmployee = async () => {
      isWaiting.value = true;
      const createdEmployee = {
        employee_first_name: createEmployeeFirstName.value,
        employee_last_name: createEmployeeLastName.value,
        employee_email: createEmployeeInfo.value,
        employee_position: createEmployePosition.value,
      };
      store
        .dispatch("createEmployee", createdEmployee)
        .then(() => {
          const newEmployees = store.getters.getEmployees.employees;
          context.emit("employee-created-successfully", newEmployees);
          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("employee-creation-unsuccessful", error);
          console.error(error);
        });
      context.emit("close-modal", createdEmployee);
      closeCreateModal();
    };

    const resetForm = () => {
      createEmployeeFirstName.value = "";
      createEmployeeLastName.value = "";
      createEmployeeInfo.value = "";
      createEmployePosition.value = "";
    };

    const closeCreateModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    return {
      createEmployeeFirstName,
      createEmployeeLastName,
      createEmployeeInfo,
      createEmployePosition,
      sendCreateEmployee,
      openModal,
      closeCreateModal,
      isWaiting,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 15vh;
  width: 50rem;
  height: 20rem;
  left: calc(50% - 25rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>