export default {
  username(state) {
    return state.displayname;
  },
  token(state) {
    return state.token;
  },
  useremail(state) {
    return state.email;
  },
  isManager(state) {
    return state.isManager;
  },
  didLogout(state) {
    return state.didLogout;
  },
  userid(state) {
    return state.userid;
  },
  pagesVisited(state) {
    return {
      assetPage: state.assetPageVisited,
      vendorPage: state.vendorPageVisited,
      dashboardPage: state.dashboardPageVisited,
      applicationPage: state.applicationPageVisited,
      documentPage: state.documentPageVisited,
      managerEmployeePage: state.managerEmployeePageVisited,
      managerRolePage: state.managerRolePageVisited,
      managerTaskPage: state.managerTaskPageVisited,
    };
  },
};
