<template>
  <div class="p-grid">
    <div id="logo" class="site-logo p-col-3">
      <slot name="logo"></slot>
    </div>
    <div id="searchBar" class="search-bar p-col-5">
      <slot name="searchBar"></slot>
    </div>
    <div id="userMenu" class="user-menu p-col-3" @click="OpenMenu($event)">
      <slot name="userMenu"> </slot>
      <Menu ref="userMenu" :model="menuItems" :popup="true" id="userMenu" />
    </div>
    <div id="helpIcon" class="help-icon p-col-1" @click="OpenVideoModal()">
      <i
        id="help-icon"
        class="pi pi-question-circle p-text-nowrap p-d-inline-flex"
        style="fontsize: 2rem; color: darkblue"
        @click="OpenVideoModal()"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    OpenMenu(event) {
      this.$refs.userMenu.toggle(event);
    },
    OpenVideoModal() {
      this.$emit("openVideoModal");
    },
  },

  data() {
    return {
      menuItems: [
        {
          label: "Change Password",
          command: () => {
            this.$emit("changePassword");
          },
        },
        {
          label: "Log Out",
          to: "/login",
          command: () => {
            this.$store.dispatch("logout");
          },
        },
      ],
    };
  },
};
</script>

<style scoped>
#helpIcon {
  display: flex;
}

#help-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#userMenu {
  padding-right: 0;
  justify-content: flex-end;
}
</style>