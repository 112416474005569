import devAPI from "../../../api/devAPI.js";
import axios from "axios";

export default {
  /**
   * Gets the employees from the backend.
   * @param {Object} context The default context object. See {@link https://vuex.vuejs.org/guide/actions.html} for more information.
   *
   */
  async getEmployees(context) {
    const token = localStorage.getItem("token"); //The token is stored in the user's browser local storage.

    return devAPI
      .get("employees", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Employees",
        },
      })
      .then((response) => {
        //We are using an arrow function. The response will always include
        //a data property. The wordpress backend will almost always have
        //a data property, which is why you see response.data.data.
        //We are commiting the data to the store. See mutations.js in this
        //module to see more.
        context.commit("setEmployees", response.data.data);
      });
  },

  async createEmployee(context, payload) {
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    const action = "Create-Employee";
    return devAPI
      .post("employees", dataFromForm, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then((response) => {
        const employeeResponse = response.data.data;
        const newEmployee = {
          employee_first_name: employeeResponse.employee_first_name,
          employee_last_name: employeeResponse.employee_last_name,
          employee_email: employeeResponse.employee_email,
          employee_position: employeeResponse.employee_position,
          employee_company: employeeResponse.employee_company,
          employee_personalScore: employeeResponse.employee_personalScore,
          key: employeeResponse.employee_key,
          postID: employeeResponse.postID,
        };
        context.commit("addEmployee", newEmployee);
      });
  },

  async getEmployeeNames(context) {
    const token = localStorage.getItem("token");
    return devAPI
      .get("employees", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Employee-Names",
        },
      })
      .then(async (response) => {
        await context.commit("setEmployeeNames", response.data.data);
      });
  },

  /**
   *
   * @param {Object} payload The employees to edit
   */
  async editEmployees(context, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    const objToSend = {
      employee_company: payload.employee_company,
      employee_email: payload.employee_email,
      employee_first_name: payload.employee_first_name,
      employee_last_name: payload.employee_last_name,
      employee_key: payload.employee_key,
      employee_position: payload.employee_position,
      employee_original_email: payload.employee_original_email,
    };
    return devAPI.post("employees", objToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: "Edit-Employees", //This is required for the backend to route the request properly.
      },
    });
  },

  async pageVisited(context, payload) {
    const token = localStorage.getItem("token");
    const action = "Page-Visited";
    const payloadToSend = { pageVisited: payload };
    devAPI.post("employees", payloadToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: action,
      },
    });
  },

  //deleteEmployee archives the Employee in the data base
  async deleteEmployee(context, payload) {
    const postID = payload.postID;
    const rowNum = payload.rowNum;
    const token = localStorage.getItem("token");
    const url = "https://zekteck.net/wp-json/zekteck/v1/employees";
    const action = "Delete-Employee";
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        Action: action,
        postID: postID,
        rowNum: rowNum,
      },
    });
  },
};
