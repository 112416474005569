<template>
  <div class="container" v-if="vendorData != null">
    <DataTable
      :value="vendorData"
      responsiveLayout="scroll"
      editMode="row"
      datakey="id"
      v-model:editingRows="editingRows"
      v-model:expandedRows="expandedRows"
      @rowEditInit="onRowEditInit"
      @rowEditCancel="onRowEditCancel"
      @rowEditSave="onRowEditComplete"
      :paginator="true"
      :rows="6"
    >
      <template #empty>No vendors were found.</template>
      <Column :expander="true" headerStyle="width: 3rem" />
      <Column field="businessName" header="Business Name" :sortable="true">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]" />
        </template>
      </Column>
      <Column field="contactInfo" header="Contact Phone" :sortable="true">
        <template #editor="slotProps">
          <InputMask
            mask="(999) 999-9999"
            v-model="slotProps.data[slotProps.column.props.field]"
          />
        </template>
      </Column>
      <Column field="address" header="Address" :sortable="true">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.props.field]" />
        </template>
      </Column>
      <Column field="checkedIn" header="Checked In" :sortable="true"> </Column>
      <Column field="baa" header="BAA" :sortable="true">
        <template #body="slotProps">
          <span
            v-if="slotProps.data.baa === 'No'"
            class="clickable"
            @click="addBAA(slotProps.data)"
            ><i class="pi pi-exclamation-triangle" style="color: red"></i
            >&nbsp;No agreement was found.</span
          >
          <span
            v-if="slotProps.data.baa === 'Yes'"
            class="clickable"
            @click="downloadBAA(slotProps.data)"
            ><i class="pi pi-download"></i>&nbsp;Download Agreement</span
          >
        </template>
      </Column>
      <Column
        :rowEditor="true"
        headerStyle="width:7rem"
        bodyStyle="text-align:center;"
      >
      </Column>
      <Column
        columnKey="{{'vendor-menu-' + key}}"
        headerStyle="width: 3rem; text-align:center;"
        bodyStyle="text-align:center; overflow: visible;"
      >
        <template #body="slotProps">
          <i
            class="pi pi-cog task-menu clickable"
            @click="openMenu($event, slotProps.data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="workLogs-subtable">
          <h3>Work Logs</h3>
          <DataTable
            :value="slotProps.data.workLog"
            responsiveLayout="scroll"
            v-model:editingRows="editingRows"
            @rowEditInit="onSubRowEditInit"
            @rowEditCancel="onRowEditCancel"
            @rowEditSave="onSubRowEditComplete"
          >
            <template #empty> No work logs found for this vendor. </template>
            <Column field="onSite" header="On Site" :sortable="true"></Column>
            <Column
              field="workDescription"
              header="Description"
              sortable
            ></Column>
            <Column field="checkIn" header="Check In Time" :sortable="true">
              <template #editor="slotProps">
                <!--<Datetime
                  type="datetime"
                  v-model="slotProps.data[slotProps.column.props.field]"
                ></Datetime>-->
                <InputText
                  type="text"
                  v-model="slotProps.data[slotProps.column.props.field]"
                />
              </template>
            </Column>
            <Column field="checkOut" header="Check Out Time" :sortable="true"
              ><template #editor="slotProps">
                <!--<Datetime
                  type="datetime"
                  v-model="slotProps.data[slotProps.column.props.field]"
                ></Datetime>-->
                <InputText
                  type="text"
                  v-model="slotProps.data[slotProps.column.props.field]"
                /> </template
            ></Column>
            <Column
              field="checkInUser"
              header="Check In User"
              :sortable="true"
            ></Column>
            <Column
              field="checkOutUser"
              header="Check Out User"
              :sortable="true"
            ></Column>
            <Column
              :rowEditor="true"
              headerStyle="width:7rem"
              bodyStyle="text-align:center"
            >
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
    <Menu
      ref="vendorMenu"
      :model="menu.menuItems"
      :popup="true"
      id="vendorMenu"
    />
    <div class="p-col-3">
      <Button
        label="Add Vendor"
        icon="pi pi-plus p-button-lg"
        @click="addVendor"
      />
    </div>
  </div>
</template>
<script>
import Column from "primevue/column";
import { useStore } from "vuex";
import { ref, reactive, computed, onMounted, defineAsyncComponent } from "vue";
const InputMask = defineAsyncComponent(() => import("primevue/inputmask"));

export default {
  components: {
    Column,
    InputMask,
  },
  props: ["vendors"],
  setup(props, context) {
    const store = useStore();
    const isManager =
      localStorage.getItem("isManager") == "true" ? true : false;
    const checkedInLabel = ref("");
    const editingRows = ref([]);
    const expandedRows = ref([]);
    const vendorData = computed({
      get: () => store.getters.getVendors,
      set: (value) => store.commit("setVendors", value),
    });
    const clickedRow = reactive({});
    const changedWorkLog = ref([]);
    const originalRows = reactive({});
    const originalSubRows = reactive({});
    const vendorMenu = ref(null);

    const menu = reactive({
      menuItems: [
        {
          label: "",
          command: (event) => {
            const changedVendor = {
              ...clickedRow,
            };
            if (changedVendor.workLog != null) {
              //Set the SingleWorkLog to the last worklog in the vendors array
              const singleWorkLog = changedVendor.workLog.slice(-1)[0];
              changedVendor.workLog = singleWorkLog;
            }
            //Switch the label when the check out button is called
            if (event.item.label == "Check Out") {
              //Revert the label value
              event.item.label = "Check In";
              context.emit("check-out", changedVendor);
            } else if (event.item.label == "Check In") {
              //revert the label value
              event.item.label = "Check Out";
              //If the asset is checked out then we call the check in function
              context.emit("check-in", changedVendor);
            } else {
              console.log(
                "There was an error setting the label of the checkIn button"
              );
            }
          },
        },
        {
          label: "Add BAA",
          command: () => {
            context.emit("add-BAA", clickedRow);
          },
        },
      ],
    });

    function addVendor() {
      context.emit("add-Vendor");
    }

    function addBAA(data) {
      context.emit("add-BAA", data);
    }

    function onRowEditInit(event) {
      store.dispatch("vendorEditRow", event.data);
    }

    function downloadBAA(data) {
      store.dispatch("downloadBAA", data).then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const mime = require("mime-types");
        const extension = mime.extension(response.headers["content-type"]);
        const fileName =
          data.businessName.replace(/\W+/g, "-").toLowerCase() +
          "-business-associate-agreement" +
          "." +
          extension;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
    }

    function onRowEditCancel() {
      const originalRow = store.getters.getOriginalVendorRow;
      const originalVendorIndex = vendorData.value.findIndex(
        (vendor) => vendor.key === originalRow.key
      );

      Object.assign(vendorData.value[originalVendorIndex], originalRow);
    }

    async function onRowEditComplete(event) {
      store.dispatch("editVendors", event.data).then(() => {
        context.emit("vendor-edited", event);
      });
    }

    function onSubRowEditInit(event) {
      changedWorkLog.value = event.data.workLog;
    }

    function onSubRowEditComplete(event) {
      changedWorkLog.value = event.data.workLog;
    }

    onMounted(() => {
      if (isManager) {
        menu.menuItems.push({
          label: "Delete Vendor",
          command: () => {
            context.emit("vendor-deleted", clickedRow);
          },
        });
      }
    });

    function openMenu(event, data) {
      Object.assign(clickedRow, data);
      if (data.checkedIn == "No" || data.checkedIn == null) {
        this.menu.menuItems[0].label = "Check In";
      } else {
        this.menu.menuItems[0].label = "Check Out";
      }
      vendorMenu.value.toggle(event);
    }
    return {
      checkedInLabel,
      editingRows,
      expandedRows,
      vendorData,
      clickedRow,
      changedWorkLog,
      originalRows,
      originalSubRows,
      vendorMenu,
      menu,
      addVendor,
      onRowEditInit,
      onRowEditCancel,
      onRowEditComplete,
      onSubRowEditInit,
      onSubRowEditComplete,
      openMenu,
      InputMask,
      downloadBAA,
      addBAA,
    };
  },
};
</script>

<style scoped>
.workLogs-subtable {
  padding: 1rem;
}
</style>