<template>
  <div class="footer-copyright text-center py-3 pt-4">
    &copy; {{ year }}&nbsp;-&nbsp;&nbsp;<a href="#">Zeckteck Client Portal</a>
  </div>
</template>

<script>
export default {
  computed: {
    year: () => {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.footer-copyright {
  margin-top: 1rem;
}
</style>
