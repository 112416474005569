<template>
  <div class="stepsdemo-content">
    <Card>
      <template v-slot:content>
        <div class="p-fluid">
          <div class="p-field">
            <label for="applications">Assigned Application(s)</label>
            <MultiSelect
              v-model="selectedApplications"
              :options="applicationNames"
              optionLabel="label"
              optionValue="value"
              name="applications"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-between">
          <i></i>
          <Button
            label="Create Role"
            @click="complete()"
            :icon="buttonIcon()"
            iconPos="right"
            class="p-button-success"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  props: ["roleBeingAdded"],
  setup(props, context) {
    const store = useStore();
    const applicationNames = ref([]);
    const selectedApplications = ref([]);
    const addingRole = computed(() => {
      return props.roleBeingAdded;
    });
    function complete() {
      context.emit("complete", {
        formData: { applications: this.selectedApplications },
      });
    }

    function prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    }

    function buttonIcon(addingRole) {
      if (addingRole) {
        return "pi pi-spin pi-spinner";
      } else {
        return "pi pi-check";
      }
    }

    const getApplicationNames = async () => {
      await store.dispatch("getApplications").then(() => {
        const returnedData = store.getters.getApplications;
        const applications = returnedData.applications;
        let appNames = [];
        applications.forEach((app) => {
          appNames.push(app.name);
        });
        let nameObjects = [];
        appNames.forEach((name) => {
          const nameObj = {
            label: name,
            value: name,
          };
          nameObjects.push(nameObj);
        });
        applicationNames.value.push(...nameObjects);
      });
    };
    onMounted(getApplicationNames);

    return {
      complete,
      prevPage,
      getApplicationNames,
      applicationNames,
      selectedApplications,
      addingRole,
      buttonIcon,
    };
  },
};
</script>