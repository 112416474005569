export default {
  setUser(state, payload) {
    state.token = payload.token;
    state.firstName = payload.firstName;
    state.lastName = payload.lastName;
    state.company = payload.company;
    state.login = payload.login;
    state.isManager = payload.isManager;
    state.email = payload.email;
    state.displayname = payload.displayname;
    state.userid = payload.userid;
    state.assetPageVisited = payload.assetPageVisited;
    state.vendorPageVisited = payload.vendorPageVisited;
    state.dashboardPageVisited = payload.dashboardPageVisited;
    state.applicationPageVisited = payload.applicationPageVisited;
    state.documentePageVisited = payload.documentePageVisited;
    state.managerEmployeePageVisited = payload.managerEmployeePageVisited;
    state.managerRolePageVisited = payload.managerRolePageVisited;
    state.managerTaskPageVisited = payload.managerTaskPageVisited;
  },
  didLogout(state) {
    state.didLogout = true;
    console.log("state changed for didLogout");
    console.log(state.didLogout);
  },
};
