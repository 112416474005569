import devAPI from "../../../api/devAPI.js";
export default {
  async getAssets(context) {
    context.commit("assetsLoading", true);
    const token = localStorage.getItem("token"); //The token is stored in the user's browser local storage.
    return devAPI
      .get("assets", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Assets",
        },
      })
      .then((response) => {
        context.commit("setAssets", response.data.data);
        context.commit("assetsLoading", false);
      });
  },

  editAssetRow(context, payload) {
    context.commit("assetEditRow", payload);
  },

  async getEmployeeNames(context) {
    const token = localStorage.getItem("token");
    return devAPI
      .get("employees", {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Get-Employee-Names",
        },
      })
      .then(async (response) => {
        await context.commit("setEmployeeNames", response.data.data);
      });
  },

  async checkInAsset(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI
      .post("assets", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "CheckIn-Asset", //This is required for the backend to route the request properly.
        },
      })
      .then(() => context.commit("checkInAsset", payload));
  },

  async checkOutAsset(context, payload) {
    const token = localStorage.getItem("token");
    return devAPI
      .post("assets", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "CheckOut-Asset", //This is required for the backend to route the request properly.
        },
      })
      .then(() => context.commit("checkOutAsset", payload));
  },

  async editAsset(context, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI
      .post("assets", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Edit-Asset", //This is required for the backend to route the request properly.
        },
      })
      .then(async () => {
        await context.dispatch("getAssets");
      });
  },

  async createAsset(context, payload) {
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    const action = "Create-Asset";
    return devAPI
      .post("assets", dataFromForm, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then((response) => {
        const assetResponse = response.data.data;
        const newAsset = {
          name: assetResponse.name,
          type: assetResponse.type,
          location: assetResponse.location,
          label: assetResponse.label,
          // description: assetResponse.description,
          employees: assetResponse.employees.join(", "),
          checkedOut: "No",
          key: assetResponse.key,
          rowNum: assetResponse.rowNum,
          postID: assetResponse.postID,
        };
        context.commit("addAsset", newAsset);
      });
  },

  async deleteAsset(context, payload) {
    const token = localStorage.getItem("token");
    //Here, we are passing the payload as data to
    //the API. This is always after the first parameter.
    return devAPI
      .post("assets", payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: "Delete-Asset", //This is required for the backend to route the request properly.
        },
      })
      .then(() => {
        context.commit("deleteAsset", payload);
      });
  },
};
