<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12">
            <label for="baa">Upload Business Associate Agreement </label>
            <FileUpload
              name="baa"
              :customUpload="true"
              @uploader="uploadBAA"
              :auto="true"
            >
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="addBAA()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const FileUpload = defineAsyncComponent(() => import("primevue/fileupload"));
export default {
  props: ["openBAAModal", "parentVendor"],
  components: {
    FileUpload,
  },
  setup(props, context) {
    const store = useStore();
    const uploadedFile = ref(null);
    const selectedVendor = computed(() => props.parentVendor);
    const openModal = computed(() => props.openBAAModal);
    const isWaiting = ref(false);
    const uploadBAA = (event) => {
      uploadedFile.value = event.files;
    };
    const addBAA = async () => {
      isWaiting.value = true;
      //upload the file
      const updatedVendor = {
        businessName: selectedVendor.value.businessName,
        contactInfo: selectedVendor.value.contactInfo,
        address: selectedVendor.value.address,
        checkedIn: selectedVendor.value.checkedIn,
        baa: uploadedFile.value,
        workLog: selectedVendor.value,
        key: selectedVendor.value.key,
        rowNum: selectedVendor.value.rowNum,
        postID: selectedVendor.value.postID,
      };
      let formData = new FormData();
      for (const [key, value] of Object.entries(updatedVendor)) {
        const isFile = (val) => {
          if (Array.isArray(val)) {
            if (val[0] instanceof File) {
              return true;
            } else return false;
          }
        };
        if (!isFile(value)) {
          formData.append(key, value);
        } else {
          formData.append(
            "baaDocument",
            uploadedFile.value[0],
            uploadedFile.value[0].name
          );
        }
      }
      store
        .dispatch("addBAA", formData)
        .then(async () => {
          const newVendors = await store.getters.getVendors.vendors;
          context.emit("baa-added-successfully", newVendors);
          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("baa-addition-unsuccessful", error);
          console.error(error);
        });
      closeModal();
    };

    const resetForm = () => {};

    const closeModal = () => {
      context.emit("close-baa-modal");
      resetForm();
    };

    return {
      addBAA,
      openModal,
      closeModal,
      isWaiting,
      FileUpload,
      uploadBAA,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>