<template>
  <div class="createAssetModal">
    <AddAssetModal
      @asset-created-successfully="assetCreatedSuccessfully"
      @asset-creation-unsuccessful="assetCreateFailed"
      @close-modal="closeCreateAssetModal"
      :openCreateModal="createAssetModalShow"
    />
  </div>
  <div class="editAssetModal">
    <EditAssetModal
      @asset-edited-successfully="assetEditSuccess"
      @asset-edited-unsuccessfully="assetEditFailed"
      @close-edit-modal="closeEditAssetModal"
      :openEditModal="editAssetModalShow"
    />
  </div>
  <AssetsDataTable
    :assets="assets"
    @edit-asset-clicked="openEditAssetModal"
    @asset-edited="assetEditSuccess"
    @delete-asset-clicked="assetDelete"
    @add-asset="openCreateAssetModal"
    @check-in="assetCheckIn"
    @check-out="assetCheckOut"
  />
  <Toast />
  <ConfirmDialog />
</template>

<script>
import AssetsDataTable from "../../components/assets/AssetsDataTable.vue";
import EditAssetModal from "../../components/assets/EditAssetModal.vue";
import AddAssetModal from "../../components/assets/AddAssetModal.vue";
import ConfirmDialog from "primevue/confirmdialog";
export default {
  components: {
    AssetsDataTable,
    AddAssetModal,
    ConfirmDialog,
    EditAssetModal,
  },
  data() {
    return {
      assets: [],
      createAssetModalShow: false,
      editAssetModalShow: false,
      selectedRow: null,
      originalAssets: [],
      isWaiting: false,
    };
  },
  methods: {
    assetDelete(deletedAsset) {
      this.$confirm.require({
        message: "Are you sure you want to delete this Asset?",
        header: "Delete confirmation",
        accept: () => {
          this.$store
            .dispatch("deleteAsset", deletedAsset)
            .then(() => {
              this.assets = this.$store.getters.getAssets;
              this.$toast.add({
                severity: "success",
                summary: "Asset Deleted.",
                life: 5000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Asset Delete failed",
                life: 5000,
              });
            });
        },
      });
    },

    assetEditSuccess() {
      this.assets = this.$store.getters.getAssets;
      this.$toast.add({
        severity: "success",
        summary: "Asset edited successfully.",
        life: 4000,
      });
    },

    assetEditFailed() {
      this.$toast.add({
        severity: "error",
        summary: "Asset edited successfully.",
        life: 4000,
      });
    },

    assetCreateFailed() {
      this.$toast.add({
        severity: "error",
        summary: "Asset Creation failed.",
        life: 5000,
      });
    },

    openCreateAssetModal() {
      this.createAssetModalShow = true;
    },

    closeCreateAssetModal() {
      this.createAssetModalShow = false;
    },

    openEditAssetModal() {
      this.editAssetModalShow = true;
    },

    closeEditAssetModal() {
      this.editAssetModalShow = false;
    },

    assetCreatedSuccessfully() {
      this.assets = this.$store.getters.getAssets;
      this.$toast.add({
        severity: "success",
        summary: "Asset Created Successfully.",
        life: 5000,
      });
    },

    //Updates the asset's check in status
    assetCheckIn(asset) {
      this.$store
        .dispatch("checkInAsset", asset)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Asset Checked In.",
            life: 5000,
          });
          this.assets = this.$store.getters.getAssets;
        })
        .catch((error) => {
          //In case of a filed update to the check in back end reset the vendors value and toase an error
          this.$toast.add({
            severity: "error",
            summary: "Asset failed to update",
            life: 5000,
          });
          console.error(error);
        });
    },
    assetCheckOut(asset) {
      this.$store
        .dispatch("checkOutAsset", asset)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Asset Checked Out.",
            life: 5000,
          });
          this.assets = this.$store.getters.getAssets;
        })
        .catch((error) => {
          //In case of a filed update to the check in back end reset the vendors value and toase an error
          this.$toast.add({
            severity: "error",
            summary: "Asset failed to update",
            life: 5000,
          });
          console.error(error);
        });
    },
  },

  async mounted() {
    this.$store.dispatch("getAssets").then(() => {
      const assets = this.$store.getters.getAssets ?? [];
      assets.length > 0 ? this.assets.push(...assets) : null;
    });
  },
};
</script>