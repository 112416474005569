<template>
  <form class="p-fluid p-formgrid p-grid p-mx-auto">
    <div class="p-col-12">
      <div class="p-field p-col-4 p-mx-auto">
        <label for="username" class="p-col-fixed" style="width: 40%"
          >Username</label
        >
        <InputText
          id="username"
          type="text"
          v-model="username"
          v-on:keyup.enter="submitForm"
          :class="{ 'p-invalid': invalidCredentials }"
        ></InputText>
      </div>
    </div>
    <div class="p-col-12">
      <div class="p-field p-col-4 p-mx-auto">
        <label for="password" class="p-col-fixed" style="width: 40%"
          >Password</label
        >
        <Password
          v-model="password"
          :feedback="false"
          v-on:keyup.enter="submitForm"
          :class="{ 'p-invalid': invalidCredentials }"
        ></Password>
        <small
          id="incorrect-credentials"
          v-if="invalidCredentials"
          class="p-error"
          >Username or password was incorrect.</small
        >
      </div>
    </div>
    <div class="p-col-12">
      <div class="p-field p-col-1 p-mx-auto">
        <Button label="Login" @click.prevent="submitForm" />
      </div>
    </div>
  </form>
</template>

<script>
//import Card from "primevue/card"

export default {
  data() {
    return {
      username: "",
      password: "",
      usernameIsValid: true,
      invalidCredentials: false,
    };
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (this.username === "" || this.password === "") {
        this.formIsValid = false;
        return;
      }
      await this.$store
        .dispatch("auth", {
          username: this.username,
          password: encodeURIComponent(this.password),
        })
        .then(async (response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            if (!response.errors) {
              const token = this.$store.getters.token;
              this.$cookies.set("jwt-auth", token, "7d");
              this.$router.push("/");
            } else {
              if (response.errorCode === 48) {
                this.invalidCredentials = true;
              }
            }
          }
        });
    },
  },
};
</script>

<style scoped>
</style>