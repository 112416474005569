<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCreateModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="name">Asset Name</label>
            <InputText type="text" v-model="createAssetName" name="name" />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="type">Asset Type</label>
            <Dropdown
              v-model="createAssetType"
              :options="typeOptions"
              optionLabel="name"
              optionValue="name"
              name="type"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="location">Asset Location</label>
            <InputText
              type="text"
              v-model="createAssetLocation"
              name="location"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="label">Asset Label</label>
            <InputText type="text" v-model="createAssetLabel" name="label" />
          </div>
          <!--Multi Select-->
          <div class="p-field p-col-12 p-md-6">
            <label for="employees">Assigned Employee(s)</label>
            <MultiSelect
              v-model="selectedEmployees"
              :options="employeeNames"
              optionLabel="label"
              optionValue="value"
              name="employees"
            />
          </div>
          <div class="p-field p-col-7 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="sendCreateAsset()"
            />
          </div>
          <div class="p-field p-col-7 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCreateModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  props: ["openCreateModal"],
  setup(props, context) {
    const store = useStore();
    const openModal = computed(() => props.openCreateModal);
    const createAssetName = ref("");
    const createAssetType = ref("");
    const createAssetLocation = ref("");
    const createAssetLabel = ref("");
    const createAssetEmployees = ref("");
    const selectedEmployees = ref([]);
    const employeeNames = ref([]);
    const isWaiting = ref(false);

    const typeOptions = [
      { name: "Desktop" },
      { name: "Laptop" },
      { name: "Server" },
      { name: "Medical Device" },
      { name: "Mobile Phone" },
      { name: "Desk Phone" },
      { name: "Printer" },
      { name: "Portable Hard Drive" },
      { name: "USB Storage Drive" },
      { name: "Router" },
      { name: "Switch" },
      { name: "Modem" },
      { name: "Other" },
    ];

    const sendCreateAsset = async () => {
      isWaiting.value = true;
      const createdAsset = {
        name: createAssetName.value,
        type: createAssetType.value,
        location: createAssetLocation.value,
        label: createAssetLabel.value,
        employees: selectedEmployees.value,
      };
      store
        .dispatch("createAsset", createdAsset)
        .then(() => {
          context.emit("asset-created-successfully", createdAsset);
          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("asset-creation-unsuccessful", error);
          console.error(error);
        });
      resetForm();
      context.emit("close-modal");
    };

    const resetForm = () => {
      createAssetName.value = "";
      createAssetType.value = "";
      createAssetLocation.value = "";
      createAssetLabel.value = "";
      createAssetEmployees.value = "";
      selectedEmployees.value = [];
      getEmployeeNames();
    };

    const closeCreateModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    const getEmployeeNames = async () => {
      isWaiting.value = true;
      await store.dispatch("getEmployeeNames").then(() => {
        const returnedNames = store.getters.getEmployeeNames;
        let nameObjects = [];
        returnedNames.forEach((name) => {
          const nameObj = {
            label: name,
            value: name,
          };
          nameObjects.push(nameObj);
        });
        employeeNames.value = [];
        employeeNames.value.push(...nameObjects);
      });
      isWaiting.value = false;
    };
    onMounted(getEmployeeNames);

    return {
      createAssetName,
      createAssetType,
      createAssetLocation,
      createAssetLabel,
      createAssetEmployees,
      sendCreateAsset,
      openModal,
      closeCreateModal,
      getEmployeeNames,
      employeeNames,
      selectedEmployees,
      typeOptions,
      isWaiting,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  height: 25rem;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>