import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      companyScore: null,
      personalScore: null,
      personalTasks: [],
      companyTasks: [],
      tasksOverdue: 0,
      showEditModal: false,
      showCreateModal: false,
    };
  },
  mutations,
  actions,
  getters,
};
