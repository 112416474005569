<template>
  <Card class="p-shadow-8">
    <template #header>
      <div class="p-grid p-jc-between p-pt-4 p-pr-4">
        <div class="p-col-7 p-mr-3 p-mb-0 p-px-2 p-py-0">
          <Message v-if="numOverdue > 0" severity="warn" :closable="true">{{
            numOverdue === 1
              ? `${numOverdue} task is overdue`
              : `${numOverdue} tasks are overdue`
          }}</Message>
        </div>
        <div class="p-col-3">
          <Button
            label="Add Task"
            icon="pi pi-plus p-button-lg"
            @click="addTask"
          />
        </div>
      </div>
    </template>
    <template #content>
      <DataTable
        responsiveLayout="scroll"
        :value="tasks"
        :rowClass="rowStyling"
        :paginator="true"
        :rows="6"
        sortField="dueDate"
        :sortOrder="1"
      >
        <template #empty> You have no tasks. </template>
        <Column
          headerStyle="width: 8rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
          columnKey="{{'task-badge' + key}}"
          field="isPersonal"
          :sortable="true"
        >
          <template #body="slotProps">
            <Tag
              value="Company"
              icon="pi pi-briefcase"
              class="company-task-tag"
              v-if="!slotProps.data.isPersonal"
            ></Tag>
            <Tag
              value="Personal"
              icon="pi pi-user"
              v-if="slotProps.data.isPersonal"
              class="personal-task-tag"
            ></Tag>
          </template>
        </Column>
        <Column field="name" header="Task Name" sortable="true"></Column>
        <Column
          field="description"
          header="Description"
          sortable="true"
        ></Column>
        <Column
          field="dueDate"
          header="Due Date"
          sortable="true"
          headerStyle="text-align: center"
          bodyStyle="text-align: center"
        >
          <template #body="slotProps">
            <span v-html="checkDate(slotProps.data.dueDate)"></span>
          </template>
        </Column>
        <Column
          columnKey="{{'task-menu-' + key}}"
          headerStyle="width: 8rem; text-align: center;"
          bodyStyle="text-align:center; overflow: visible"
        >
          <!-- taskMenuClick function passes back the current row's data. The function then stores this in the ClickedRow variable. -->
          <template #body="slotProps">
            <i
              class="pi pi-cog task-menu"
              @click="taskMenuClick($event, slotProps.data)"
              v-if="showCogMenu(slotProps.data)"
            />
            <i
              class="pi pi-cog task-menu-disabled"
              v-if="!slotProps.data.custom"
              v-tooltip="'This is a system-generated task'"
            />
          </template>
        </Column>
      </DataTable>
      <Menu
        ref="taskMenu"
        :model="menu.menuItems"
        :popup="true"
        id="taskMenu"
        @mouseleave="taskMenuMouseOut"
      />
    </template>
  </Card>
</template>

<script>
import { ref, reactive, computed } from "vue";
import Column from "primevue/column";
import Message from "primevue/message";
import Tag from "primevue/tag";

import { useStore } from "vuex";

export default {
  components: {
    Column,
    Message,
    Tag,
  },
  props: ["tasks"],
  setup(props, context) {
    const store = useStore();
    const taskMenu = ref(null);
    const numOverdue = computed(() => store.getters.getOverdue);
    const tasks = reactive(props.tasks);
    const clickedRow = reactive({});
    const menu = reactive({
      menuItems: [
        {
          label: "Complete Task",
          icon: "pi pi-check",
          command: () => {
            context.emit("complete-task", clickedRow);
          },
        },
        {
          label: "Edit Task",
          icon: "pi pi-pencil",
          command: () => {
            context.emit("open-edit-modal", clickedRow);
          },
        },
        {
          label: "Delete Task",
          icon: "pi pi-trash",
          command: () => {
            context.emit("delete-task", clickedRow);
          },
        },
      ],
    });
    function taskMenuClick(event, taskRow) {
      Object.assign(clickedRow, taskRow);
      taskMenu.value.toggle(event);
    }

    function showCogMenu(row) {
      const manager = localStorage.getItem("isManager");
      const isCustom = row.custom;
      if (!row.isPersonal && isCustom && manager === "true") {
        return true;
      } else if (row.isPersonal && isCustom) {
        return true;
      } else return false;
    }

    function rowStyling(data) {
      const dueDate = Date.parse(data.dueDate + " 23:59:59 GMT -08:00");
      const today = Date.now();
      if (dueDate < today) {
        return "overdue-task";
      } else return null;
    }

    function checkDate(dueDate) {
      const today = new Date();
      const dateDiff = Date.parse(dueDate + " 23:59:59 GMT -08:00") - today;
      if (dateDiff < 86400000) {
        return '<p style="color:red; font-weight:bold">' + dueDate + "</p>";
      } else {
        return dueDate;
      }
    }

    function addTask() {
      context.emit("add-task-clicked");
    }

    function taskMenuMouseOut(event) {
      taskMenu.value.toggle(event);
    }
    return {
      taskMenu,
      menu,
      taskMenuClick,
      taskMenuMouseOut,
      tasksArray: tasks,
      addTask,
      checkDate,
      numOverdue,
      Tag,
      rowStyling,
      showCogMenu,
    };
  },
};
</script>

<style scoped>
.p-message {
  margin: 0;
}

.task-menu {
  cursor: pointer;
}

.task-menu-disabled {
  opacity: 0.3;
}

.p-message-warn div.p-message-wrapper {
  padding: 0.6rem 1.5rem !important;
}
.p-tag.company-task-tag {
  font-size: 0.6rem;
  background-color: #f37b21;
}

.p-tag.personal-task-tag {
  font-size: 0.6rem;
  background-color: #3056ec;
}
</style>
