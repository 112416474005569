import devAPI from "../../../api/devAPI.js";
import axios from "axios";

export default {
  async loadDashboard(context) {
    context.commit("resetOverdue");
    return devAPI
      .get("/dashboard", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const responseData = response.data;
        const companyTasks = responseData?.data?.tasks?.companyTasks;
        const personalTasks = responseData?.data?.tasks?.personalTasks;
        if (companyTasks) {
          for (let task of companyTasks) {
            task.dueDate = new Date(task.dueDate).toLocaleString("en-us", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            if (task.isOverdue) {
              context.commit("addOverdue");
            }
          }
        }
        if (personalTasks) {
          for (let task of personalTasks) {
            task.dueDate = new Date(task.dueDate).toLocaleString("en-us", {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
            if (task.isOverdue) {
              context.commit("addOverdue");
            }
          }
        }
        context.commit("setScores", {
          companyScore: responseData.data?.companyScore,
          personalScore: responseData.data?.personalScore,
        });
        context.commit("setTasks", {
          companyTasks: responseData?.data?.tasks?.companyTasks,
          personalTasks: responseData?.data?.tasks?.personalTasks,
        });
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  },

  async getSecurityScores() {
    const authToken = localStorage.getItem("token");
    return devAPI
      .get("dashboard", {
        headers: {
          Authorization: "Bearer " + authToken,
          Action: "Get-Scores",
        },
      })
      .then((response) => {
        const responseData = response.data.data;
        return responseData;
      });
  },
  async loadTraining() {
    const authToken = localStorage.getItem("token");
    const userId = localStorage.getItem("userid");
    return axios.all([
      axios.get(process.env.VUE_APP_COURSES_API_URL + `${userId}/courses`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
      axios.get(
        process.env.VUE_APP_COURSES_API_URL + `${userId}/course-progress`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      ),
    ]);
  },

  showEditModal(context, payload) {
    context.commit("showEditModal", payload);
  },

  checkOverdueOnEdit(context, payload) {
    const today = new Date();
    const dateToCheck = Date.parse(payload);
    if (dateToCheck > today) {
      context.commit("removeOverdue");
    }
  },

  async sendEditTask(context, payload) {
    let dateFromForm = new Date(payload["edit-task-dueDate"]);
    const dateToSend =
      dateFromForm.getMonth() +
      1 +
      "/" +
      dateFromForm.getDate() +
      "/" +
      dateFromForm.getFullYear();
    payload["edit-task-dueDate"] = dateToSend;
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    let action = payload["edit-task-isPersonal"]
      ? "Edit-Personal-Task"
      : "Edit-Company-Task";
    return devAPI
      .post("/tasks", dataFromForm, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then((response) => {
        const responseData = response.data.data;
        context.commit("editTask", responseData);
        context.dispatch("getTasksForManagers");
      });
  },

  async completeTask(context, payload) {
    const task = payload;
    const action = "Complete-Task";
    const token = localStorage.getItem("token");
    return devAPI
      .post("tasks", task, {
        headers: {
          Action: action,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        const tasks = await devAPI.get("tasks", {
          headers: {
            Action: "Get-Tasks-All",
            Authorization: `Bearer ${token}`,
          },
        });
        context.commit("setTasks", tasks.data.data);
        context.dispatch("getTasksForManagers");
      });
  },
  async sendCreateTask(context, payload) {
    const dataFromForm = payload;
    const token = localStorage.getItem("token");
    const action = payload.isPersonal
      ? "Create-Personal-Task"
      : "Create-Company-Task";
    return devAPI
      .post("tasks", dataFromForm, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: action,
        },
      })
      .then((response) => {
        const taskResponse = response.data.data;
        const newTask = {
          category: taskResponse.task_category,
          description: taskResponse.task_description,
          dueDate: new Date(taskResponse.task_dueDate).toLocaleString("en-us", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            timeZone: "America/Los_Angeles",
          }),
          name: taskResponse.task_name,
          custom: taskResponse.task_custom,
          employees: taskResponse.task_employees.join(","),
          isOverdue: false,
          key: taskResponse.task_key,
          rowNum: taskResponse.rowNum,
          postID: taskResponse.postID,
          archived: taskResponse.archived,
          isPersonal: taskResponse.task_isPersonal,
        };
        context.commit("addTask", newTask);
        context.dispatch("getTasksForManagers");
      });
  },
  async deleteTask(context, payload) {
    const postID = payload.postID;
    const rowNum = payload.rowNum;
    const token = localStorage.getItem("token");
    const action = "Archive-Task";
    return devAPI
      .post("tasks", null, {
        headers: {
          Authorization: `Bearer ${token}`,
          Action: action,
          postID: postID,
          rowNum: rowNum,
        },
      })
      .then(() => {
        context.commit("removeTask", payload);
        context.dispatch("getTasksForManagers");
      });
  },
};
