import authAPI from "../../../api/authAPI";
import devAPI from "../../../api/devAPI.js";

export default {
  /**
   * This is the form handler for the auth form. This is done in two steps.
   * The first step is acquiring a JSON Web Token from the backend API. If successful,
   * the token is then used to get the user's data to be stored in the Vuex store.
   * @param {Object} context Vuex router object allowing access to actions and getters.
   * @param {mixed} payload This will contain the username and password from the auth form.
   * @see https://vuex.vuejs.org/guide/actions.html
   */
  async auth(context, payload) {
    /* const axiosOptions = {
      url: `https://portal.zekteck.dev/?rest_route=/simple-jwt-login/v1/auth&username=${payload.username}&password=${payload.password}`,
      method: "POST", //The authentication method requires a POST to the API
    }; */
    /**
     * We're returning the promise so that the component (App.vue) can handle the response.
     */

    let token = null;
    const loginData = async () =>
      await authAPI
        .post(
          `?rest_route=/simple-jwt-login/v1/auth&username=${payload.username}&password=${payload.password}`,
          null
        )
        .then((response) => {
          const responseData = response.data.data;
          token = responseData.jwt;
          localStorage.setItem("token", token); //We're setting the JSON Web Token into the browser's local storage.
          /**
           * We have to do another API call to the validate route to get all of the user information.
           * The JSON web token must be appended to the URL as shown here.
           */
          return responseData;
        })
        .then((responseData) => {
          return devAPI("employees", {
            headers: {
              Authorization: `Bearer ${responseData.jwt}`,
              Action: "Get-Employee-Info",
            },
          });
        })
        .then((response2) => {
          /**
           * The data coming back from the API is the following:
           * userId (int): The user's WordPress ID
           * username (string): The user's username
           * useremail (string): The user's email
           * isManager (bool): The user's managerial status
           * userLogin (string): The user's login name
           * didLogout (bool): Set to false to indicate that the user did not log out
           * loggedOut (bool): Set to false to show that the user is logged in.
           */
          const responseData2 = response2.data.data;
          let isManager = false;
          if (
            responseData2.isManager === true ||
            responseData2.isManager === "1"
          ) {
            isManager = true;
          } else {
            isManager = false;
          }
          localStorage.setItem("userid", responseData2.ID);
          localStorage.setItem("company", responseData2.company);
          localStorage.setItem("firstName", responseData2.firstName);
          localStorage.setItem("lastName", responseData2.lastName);
          localStorage.setItem(
            "displayName",
            responseData2.firstName + " " + responseData2.lastName
          );
          localStorage.setItem("email", responseData2.email);
          localStorage.setItem("isManager", isManager);
          localStorage.setItem("login", responseData2.login);
          localStorage.setItem("didLogout", false);
          localStorage.setItem("loggedOut", false);
          localStorage.setItem("licenses", responseData2.licenses);
          localStorage.setItem(
            "assetPageVisited",
            responseData2.assetPageVisited
          );
          localStorage.setItem(
            "vendorPageVisited",
            responseData2.vendorPageVisited
          );
          localStorage.setItem(
            "dashboardPageVisited",
            responseData2.dashboardPageVisited
          );
          localStorage.setItem(
            "applicationPageVisited",
            responseData2.applicationPageVisited
          );
          localStorage.setItem(
            "managerEmployeePageVisited",
            responseData2.managerEmployeePageVisited
          );
          localStorage.setItem(
            "managerRolePageVisited",
            responseData2.managerRolePageVisited
          );
          localStorage.setItem(
            "managerTaskPageVisited",
            responseData2.managerTaskPageVisited
          );
          /**
           * Setting the data into the Vuex store. See actions.js in this module.
           * setUser method in actions.js sets the necessary user data for app use.
           */
          context.commit("setUser", {
            token: token,
            firstName: responseData2.firstName,
            lastName: responseData2.lastName,
            isManager: isManager,
            email: responseData2.email,
            displayname: responseData2.firstName + " " + responseData2.lastName,
            userid: responseData2.ID,
            company: responseData2.company,
            login: responseData2.login,
            assetPageVisited: responseData2.assetPageVisited,
            vendorPageVisited: responseData2.vendorPageVisited,
            dashboardPageVisited: responseData2.dashboardPageVisited,
            applicationPageVisited: responseData2.applicationPageVisited,
            documentPageVisited: responseData2.documentPageVisited,
            managerEmployeePageVisited:
              responseData2.managerEmployeePageVisited,
            managerRolePageVisited: responseData2.managerRolePageVisited,
            managerTaskPageVisited: responseData2.managerTaskPageVisited,
          });
          return {
            status: "success",
            errors: false,
          };
        })
        .catch((error) => {
          const errorData = error.response.data.data;
          return {
            errors: true,
            errorCode: errorData.errorCode,
          };
        });
    return await loginData();
  },

  /**
   * This checks to see if the user was previously logged in.
   * If so, the existing JSON Web Token is checked against the API and,
   * if valid, they bypass the login screen.
   * @param {Object} context Default Vuex context object
   */
  async tryLogin(context) {
    const token = localStorage.getItem("token");
    //const url = `https://portal.zekteck.dev/?rest_route=/simple-jwt-login/v1/auth/validate`;
    const username = localStorage.getItem("displayName");
    const useremail = localStorage.getItem("email");
    const userId = localStorage.getItem("userid");

    if (!token || !username || !useremail || !userId) {
      context.dispatch("logout");
      return;
    }

    authAPI
      .get("", {
        params: {
          rest_route: "/simple-jwt-login/v1/auth/validate",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const status = response.data.success;
        if (status == true) {
          return devAPI
            .get("employees", {
              headers: {
                Authorization: `Bearer ${token}`,
                Action: "Get-Employee-Info",
              },
            })
            .then(async (response2) => {
              const empData = response2.data.data;
              let isManager = empData.isManager;
              if (isManager === true || isManager === "1") {
                isManager = true;
              } else {
                isManager = false;
              }
              localStorage.setItem("userid", empData.ID);
              localStorage.setItem("company", empData.company);
              localStorage.setItem("firstName", empData.firstName);
              localStorage.setItem("lastName", empData.lastName);
              localStorage.setItem(
                "displayName",
                empData.firstName + " " + empData.lastName
              );
              localStorage.setItem("email", empData.email);
              localStorage.setItem("isManager", isManager);
              localStorage.setItem("login", empData.login);
              localStorage.setItem("didLogout", false);
              localStorage.setItem("loggedOut", false);
              context.commit("setUser", {
                token: token,
                login: empData.login,
                isManager: isManager,
                email: empData.email,
                displayname: empData.firstName + " " + empData.lastName,
                userid: empData.ID,
                company: empData.company,
              });
            });
        } else {
          context.dispatch("logout");
        }
      })
      .catch((error) => console.log(error.response));
  },
  /**
   * This is the logout method. It sets the user data to null,
   * sets the didLogout bool to true, and loggedOut bool to true.
   * It also resets the Vuex store.
   * @param {Object} context The default context object
   */
  logout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("displayName");
    localStorage.removeItem("login");
    localStorage.removeItem("email");
    localStorage.removeItem("isManager");
    localStorage.removeItem("company");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("userid");
    context.commit("setUser", {
      token: null,
      displayName: null,
      email: null,
      isManager: false,
      login: null,
      company: null,
      firstName: null,
      lastName: null,
      userid: null,
    });
    context.commit("didLogout");
  },

  async changePassword(context, payload) {
    const token = localStorage.getItem("token");
    const username = payload.username;
    const cp = btoa(payload.currentPass);
    const np = btoa(payload.newPass);
    return await devAPI.post("auth", null, {
      headers: {
        Authorization: `Bearer ${token}`,
        username: username,
        Action: "Change-Password",
        cp: cp,
        np: np,
      },
    });
  },
};
