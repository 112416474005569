export default {
  /**
   * Sets the employees into the Vuex state. The employees are retrieved by actions.js and then passed into the mutation for storage.
   * @param {Object} state The state object for the store. See {@link https://next.vuex.vuejs.org/guide/state.html} for more information.
   * @param {*} payload This has an array of employees retrieved from the backend. This is called by an action in actions.js in this module.
   */
  setEmployees(state, payload) {
    state.employees = payload;
  },

  setEmployeeNames(state, payload) {
    state.employeeNames = payload;
  },

  addEmployee(state, payload) {
    state.employees.push(payload);
  },
};
