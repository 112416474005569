<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCreateModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="businessName">Business Name</label>
            <InputText
              type="text"
              v-model="createVendorName"
              name="businessName"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="contactInfo">Business Phone</label>
            <InputMask
              v-model="createVendorPhone"
              mask="(999) 999-9999"
              name="contactInfo"
              placeholder="(123) 456-7890"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="address">Business Address</label>
            <InputText
              type="text"
              v-model="createVendorAddress"
              name="address"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="address">Upload Business Associate Agreement</label>
            <!--  The customUpload="true" allows us to control the upload process. This works with the @uploader="baaUploader" which is a callback function.
                  The :clear="cancelUpload" is a callback function for when the user cancels the upload.
                  The :showUploadButton="false" should hide the upload button, but I'm finding that this isn't working for some reason. -->
            <FileUpload
              name="baa"
              accept=".csv,text/plain,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
              :customUpload="true"
              :auto="true"
              :clear="cancelUpload"
              :showUploadButton="false"
              :maxFileSize="10482960"
              :fileLimit="1"
              @uploader="baaUploader"
            >
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="sendCreateVendor()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCreateModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
const InputMask = defineAsyncComponent(() => import("primevue/inputmask"));
const FileUpload = defineAsyncComponent(() => import("primevue/fileupload"));

export default {
  props: ["openCreateModal"],
  components: {
    InputMask,
    FileUpload,
  },
  setup(props, context) {
    const store = useStore();
    const createVendorName = ref("");
    const createVendorAddress = ref("");
    const openModal = computed(() => props.openCreateModal);
    const uploadedFile = ref(""); //This is where we will store the file when the user clicks Upload.
    const createVendorPhone = ref("");
    const isWaiting = ref(false);
    const sendCreateVendor = async () => {
      isWaiting.value = true;
      const createdVendor = {
        businessName: createVendorName.value,
        contactInfo: createVendorPhone.value,
        address: createVendorAddress.value,
        checkedIn: false,
        baa: uploadedFile.value,
        workLog: [],
      };
      let formData = new FormData();
      formData.append("businessName", createVendorName.value);
      formData.append("address", createVendorAddress.value);
      formData.append("contactInfo", createVendorPhone.value);
      uploadedFile.value == ""
        ? formData.append("baaDocument", null)
        : formData.append(
            "baaDocument",
            uploadedFile.value[0],
            uploadedFile.value[0].name
          );
      store
        .dispatch("createVendor", formData)
        .then(() => {
          const newVendors = store.getters.getVendors.vendors;
          context.emit("vendor-created-successfully", newVendors);
          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("Vendor-creation-unsuccessful", error);
          console.error(error);
        });
      context.emit("close-modal", createdVendor);
      closeCreateModal();
    };

    //This is the custom function for when the user clicks Upload. We are simply storing the file (event.files) in a variable called uploadedFile.
    const baaUploader = (event) => {
      uploadedFile.value = event.files;
    };
    //This sets the value of the uploadedFile variable to null when the user clicks cancel.
    const cancelUpload = () => {
      uploadedFile.value = null;
    };

    const resetForm = () => {
      createVendorName.value = "";
      createVendorPhone.value = "";
      createVendorAddress.value = "";
      uploadedFile.value = "";
    };

    const closeCreateModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    return {
      createVendorName,
      createVendorPhone,
      createVendorAddress,
      uploadedFile,
      baaUploader,
      sendCreateVendor,
      openModal,
      closeCreateModal,
      isWaiting,
      InputMask,
      FileUpload,
      cancelUpload,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 15vh;
  width: 50rem;
  height: 33rem;
  left: calc(50% - 25rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>