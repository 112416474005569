<template>
  <div class="container">
    <Menubar :model="menuitems" />
    <router-view></router-view>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
export default {
  components: {
    Menubar,
  },
  data() {
    return {
      menuitems: [
        /*{
          label: "Company",
          icon: "pi pi-briefcase",
          to: "/manager/company",
        },*/
        {
          label: "Employees",
          icon: "pi pi-users",
          to: "/manager/employees",
        },
        {
          label: "Roles",
          icon: "pi pi-tags",
          to: "/manager/roles",
        },
        {
          label: "Tasks",
          icon: "pi pi-inbox",
          to: "/manager/tasks",
        },
      ],
    };
  },
};
</script>