<!-- Component template HTML -->
<template>
  <div class="container">
    <div v-if="openModal" class="backdrop" @click="closeCreateModal"></div>
    <transition name="modal">
      <dialog open v-if="openModal">
        <div class="p-d-flex p-jc-center p-ai-center" v-if="isWaiting">
          <ProgressSpinner
            style="width: 5vh; height: 5vh"
            strokeWidth="8"
            fill="#fff"
            animationDuration=".5s"
            class="modal-spinner"
          />
        </div>
        <form
          class="p-fluid p-formgrid p-grid p-mx-auto"
          id="create-modal-form"
          :class="{ fadeOut: isWaiting }"
        >
          <div class="p-field p-col-12 p-md-6">
            <label for="name">Application Name</label>
            <InputText
              type="text"
              v-model="createApplicationName"
              name="name"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="description">Application Description</label>
            <InputText
              type="text"
              v-model="createApplicationDescription"
              name="description"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="category">Application Category</label>
            <Dropdown
              v-model="createApplicationCategory"
              :options="categoryOptions"
              optionLabel="name"
              optionValue="name"
              name="category"
            />
          </div>
          <div class="p-formgroup-inline">
            <div class="p-field-checkbox p-col-6 p-md-3">
              <label for="ephi">EPHI</label>
              <Checkbox
                v-model="createApplicationEPHI"
                :binary="true"
                id="ephi"
              />
            </div>
            <div class="p-field-checkbox p-col-6 p-md-3">
              <label for="pii">PII</label>
              <Checkbox
                v-model="createApplicationPII"
                :binary="true"
                id="pii"
              />
            </div>
            <div class="p-field-checkbox p-col-6 p-md-3">
              <label for="pci">PCI</label>
              <Checkbox
                v-model="createApplicationPCI"
                :binary="true"
                id="pci"
              />
            </div>
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-raised"
              label="Submit"
              @click.prevent="sendCreateApplication()"
            />
          </div>
          <div class="p-field p-col-4 p-mx-auto">
            <Button
              class="p-button-secondary"
              label="Cancel"
              @click.prevent="closeCreateModal"
            />
          </div>
        </form>
      </dialog>
    </transition>
  </div>
</template>

<!-- Vue Business Logic -->
<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
//import InputText from "primevue/inputtext";
//import Dropdown from "primevue/dropdown";
//import Checkbox from "primevue/checkbox";

export default {
  props: ["openCreateModal"],
  setup(props, context) {
    const store = useStore();
    const createApplicationName = ref("");
    const openModal = computed(() => props.openCreateModal);
    const createApplicationCategory = ref("");
    const createApplicationDescription = ref("");
    const createApplicationEPHI = ref("");
    const createApplicationPCI = ref("");
    const createApplicationPII = ref("");
    const isWaiting = ref(false);
    const categoryOptions = [
      { name: "E-mail" },
      { name: "Windows" },
      { name: "MacOS" },
      { name: "EMR/EHR" },
      { name: "Office" },
      { name: "File Server" },
    ];
    const sendCreateApplication = async () => {
      isWaiting.value = true;
      const createdApplication = {
        name: createApplicationName.value,
        category: createApplicationCategory.value,
        description: createApplicationDescription.value,
        ephi: createApplicationEPHI.value,
        pii: createApplicationPII.value,
        pci: createApplicationPCI.value,
      };
      store
        .dispatch("createApplication", createdApplication)
        .then((response) => {
          const returnedApp = response.data.data;
          const newApp = {
            name: returnedApp.application_name,
            category: returnedApp.application_category,
            description: returnedApp.application_description,
            ephi: returnedApp.application_ephi,
            pci: returnedApp.application_pci,
            pii: returnedApp.application_pii,
            rowNum: returnedApp.rowNum,
            key: returnedApp.application_key,
            postID: returnedApp.postID,
          };
          context.emit("application-created-successfully", newApp);
          resetForm();

          isWaiting.value = false;
        })
        .catch((error) => {
          isWaiting.value = false;
          context.emit("application-creation-unsuccessful", error);
          console.error(error);
        });
      context.emit("close-modal", createdApplication);
      closeCreateModal();
    };

    const resetForm = () => {
      createApplicationName.value = "";
      createApplicationCategory.value = "";
      createApplicationDescription.value = "";
      createApplicationEPHI.value = "";
      createApplicationPII.value = "";
      createApplicationPCI.value = "";
    };

    const closeCreateModal = () => {
      context.emit("close-modal");
      resetForm();
    };

    return {
      createApplicationName,
      createApplicationCategory,
      createApplicationDescription,
      createApplicationEPHI,
      createApplicationPII,
      createApplicationPCI,
      sendCreateApplication,
      categoryOptions,
      openModal,
      closeCreateModal,
      isWaiting,
      //Dropdown,
      //Checkbox,
    };
  },
};
</script>

<!-- Component scoped styles -->
<style scoped>
#create-modal-form {
  margin: 5% 5% 0% 5%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  height: 20rem;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.modal-spinner {
  position: absolute;
  top: 40%;
  right: 50%;
  z-index: 9999;
}
</style>