<template>
  <div v-if="openModal" class="backdrop" @click="closeModal"></div>
  <transition name="modal">
    <dialog open v-if="openModal">
      <slot name="header"></slot>
      <slot></slot>
    </dialog>
  </transition>
</template>
<script>
import { toRefs } from "vue";
export default {
  props: ["open"],
  emits: ["modal-close"],
  setup(props, context) {
    let propRefs = toRefs(props);
    let openModal = propRefs.open;
    //watch(props.open, (newValue) => (openModal.value = newValue));
    function closeModal() {
      context.emit("close-modal");
    }
    return {
      closeModal,
      openModal,
    };
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog {
  position: fixed;
  top: 30vh;
  width: 50rem;
  left: calc(50% - 15rem);
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
  /* animation: modal 0.3s ease-out forwards; */
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>
