<template>
  <div class="sidenav">
    <slot name="navLinks"></slot>
  </div>
</template>

<style scoped>
.sidenav {
  height: 100vh;
  width: 150px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 120px;
}
</style>