<template>
  <div>
    <div class="checkInVendorModal">
      <CheckInVendorModal
        @vendor-check-In="checkInVendor"
        @close-modal="closeVendorCheckInModal"
        :openCheckInModal="checkInModalShow"
        :parentVendor="selectedRow"
      />
    </div>
    <div class="checkOutVendorModal">
      <CheckOutVendorModal
        @vendor-check-out="checkOutVendor"
        @close-modal="closeVendorCheckOutModal"
        :openCheckOutModal="checkOutModalShow"
        :parentVendor="selectedRow"
      />
    </div>
    <div class="createVendorModal">
      <AddVendorModal
        @vendor-created-successfully="vendorCreatedSuccessfully"
        @vendor-creation-unsuccessful="vendorCreateFailed"
        @close-modal="closeCreateVendorModal"
        :openCreateModal="createVendorModalShow"
      />
    </div>
    <VendorDataTable
      :vendors="vendors"
      @vendor-edited="vendorEditSuccess"
      @vendor-deleted="vendorDeleteSuccess"
      @add-Vendor="openCreateVendorModal"
      @add-BAA="openAddBAAModal"
      @check-out="openVendorCheckOutModal"
      @check-in="openVendorCheckInModal"
      @worklog-edited="workLogEditSuccess"
    />
    <AddBAAModal
      :openBAAModal="addBAAModalShow"
      :parentVendor="addBAAParentVendor"
      @baa-added-successfully="successfulBAAModal"
      @baa-addition-unsuccessful="addBAAUnsuccessful"
      @close-baa-modal="closeBAAModal"
    />
    <Toast />
    <ConfirmDialog />
  </div>
</template>

<script>
import VendorDataTable from "../../components/vendors/VendorDataTable.vue";
import AddVendorModal from "../../components/vendors/AddVendorModal.vue";
import CheckInVendorModal from "../../components/vendors/CheckInVendorModal.vue";
import CheckOutVendorModal from "../../components/vendors/CheckOutVendorModal.vue";
import AddBAAModal from "../../components/vendors/AddBAAModal.vue";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  components: {
    VendorDataTable,
    ConfirmDialog,
    AddBAAModal,
    AddVendorModal,
    CheckOutVendorModal,
    CheckInVendorModal,
  },
  computed: {
    vendors() {
      return this.$store.getters.getVendors;
    },
  },
  data() {
    return {
      originalVendors: [],
      isWaiting: false,
      selectedRow: null,
      workLogs: [],
      addBAAModalShow: false,
      addBAAParentVendor: null,
      createVendorModalShow: false,
      checkInModalShow: false,
      checkOutModalShow: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("getVendors");
  },

  methods: {
    openVendorCheckInModal(vendor) {
      this.checkInModalShow = true;
      this.selectedRow = vendor;
    },

    closeVendorCheckInModal() {
      this.checkInModalShow = false;
    },

    checkInVendor(vendor) {
      this.checkInModalShow = false;
      this.selectedRow.workLog = { ...vendor.workLog };
      //call the vendor check in function in actions
      this.$store
        .dispatch("checkInVendor", this.selectedRow)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Vendor was Checked In.",
            life: 5000,
          });
        })
        .catch((error) => {
          //In case of a filed update to the check in back end reset the vendors value and toase an error
          this.$toast.add({
            severity: "danger",
            summary: "Vendor failed to Check In",
            life: 5000,
          });
          console.error(error);
        });
    },

    openVendorCheckOutModal(vendor) {
      this.checkOutModalShow = true;
      this.selectedRow = vendor;
    },

    checkOutVendor(vendor) {
      this.checkOutModalShow = false;
      this.selectedRow.workLog = { ...vendor.workLog };
      this.$store
        .dispatch("checkOutVendor", this.selectedRow)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Vendor Checked Out.",
            life: 5000,
          });
        })
        .catch((error) => {
          //In case of a filed update to the check in back end reset the vendors value and toase an error
          this.$toast.add({
            severity: "danger",
            summary: "Vendor failed to Check Out",
            life: 5000,
          });
          console.error(error);
        });
    },

    closeVendorCheckOutModal() {
      this.checkOutModalShow = false;
    },

    openCreateVendorModal() {
      this.createVendorModalShow = true;
    },

    closeCreateVendorModal() {
      this.createVendorModalShow = false;
    },

    openAddBAAModal(vendor) {
      this.addBAAParentVendor = vendor;
      this.addBAAModalShow = true;
    },

    closeBAAModal() {
      this.addBAAModalShow = false;
    },

    vendorCreatedSuccessfully() {
      //We are now getting the new values from the Vuex store.
      //This is a better approach as it is inherently reactive.
      this.$toast.add({
        severity: "success",
        summary: "Vendor Created Successfully.",
        life: 5000,
      });
    },

    vendorCreateFailed() {
      this.$toast.add({
        severity: "fail",
        summary: "Vendor Creation failed.",
        life: 5000,
      });
    },

    vendorEditSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Vendor edited successfully.",
        life: 4000,
      });
    },

    workLogEditSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Work Log edited successfully.",
        life: 4000,
      });
    },

    vendorDeleteSuccess(deletedRow) {
      this.$confirm.require({
        message: "Are you sure you want to delete this Vendor?",
        header: "Delete confirmation",
        accept: () => {
          this.$store
            .dispatch("deleteVendor", deletedRow)
            .then(() => {
              //Instead of taking care of the data changes locally, they are now being taken care of in the Vuex store.
              //As a result, we are simply getting the new values from the store upon deletion or creation.
              this.$toast.add({
                severity: "success",
                summary: "Vendor deleted.",
                life: 5000,
              });
            })
            .catch(() => {
              this.$toast.add({
                severity: "error",
                summary: "Vendor delete failed",
                life: 5000,
              });
            });
        },
      });
    },
  },
};
</script>

<style scoped>
</style>