<template>
  <div class="content">
    <Card>
      <template v-slot:content>
        <div class="p-fluid">
          <div class="p-field">
            <label for="employees">Assigned Employee(s)</label>
            <MultiSelect
              v-model="selectedEmployees"
              :options="employeeNames"
              optionLabel="label"
              optionValue="value"
              name="employees"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="p-grid p-nogutter p-justify-around">
          <Button
            label="Previous"
            @click="prevPage()"
            icon="pi pi-angle-left"
            iconPos="left"
          />
          <Button
            label="Next"
            @click="nextPage()"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  setup(_, context) {
    const store = useStore();
    const selectedEmployees = ref([]);
    const employeeNames = ref([]);

    function nextPage() {
      context.emit("next-page", {
        formData: { employees: this.selectedEmployees },
        pageIndex: 1,
      });
    }

    function prevPage() {
      context.emit("prev-page", { pageIndex: 1 });
    }

    const getEmployeeNames = async () => {
      await store.dispatch("getEmployeeNames").then(() => {
        const returnedNames = store.getters.getEmployeeNames;
        let nameObjects = [];
        returnedNames.forEach((name) => {
          const nameObj = {
            label: name,
            value: name,
          };
          nameObjects.push(nameObj);
        });
        employeeNames.value.push(...nameObjects);
      });
    };
    onMounted(getEmployeeNames);

    return {
      nextPage,
      prevPage,
      getEmployeeNames,
      employeeNames,
      selectedEmployees,
    };
  },
};
</script>