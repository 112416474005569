import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      token: "",
      firstName: "",
      lastName: "",
      company: "",
      login: "",
      isManager: false,
      email: "",
      displayname: "",
      userid: null,
      didLogout: false,
      assetPageVisited: false,
      vendorPageVisited: false,
      dashboardPageVisited: false,
      applicationPageVisited: false,
      managerEmployeePageVisited: false,
      managerRolePageVisited: false,
      managerTaskPageVisited: false,
    };
  },
  mutations,
  actions,
  getters,
};
