import { createApp, defineAsyncComponent } from "vue";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import router from "./router.js";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store/index.js";
import PrimeVue from "primevue/config";
import BadgeDirective from "primevue/badgedirective";
import Tooltip from "primevue/tooltip";
import prodAPI from "./api/api.js";
import devAPI from "./api/devAPI.js";
import VueCookies from "vue3-cookies";

//Import styles
import "primevue/resources/themes/saga-blue/theme.css"; //Standard bootstrap styling
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

const app = createApp(App);
app.config.globalProperties.$api = prodAPI; //allows the use of $api to access production API anywhere on the App
app.config.globalProperties.$devApi = devAPI; //allows the use of $devAPI to access dev API anywhere in the App.
app.use(ToastService);
app.use(ConfirmationService);
app.directive("tooltip", Tooltip);
app.directive("badge", BadgeDirective);
app.use(router);
app.use(store);

app.use(VueAxios, axios);
app.use(VueCookies);
app.use(PrimeVue, { ripple: true });

//defining async components
const InputText = defineAsyncComponent(() => import("primevue/inputtext"));
const Password = defineAsyncComponent(() => import("primevue/password"));
const Button = defineAsyncComponent(() => import("primevue/button"));
const Sidebar = defineAsyncComponent(() => import("primevue/sidebar"));
const Knob = defineAsyncComponent(() => import("primevue/knob"));
const ProgressSpinner = defineAsyncComponent(() =>
  import("primevue/progressspinner")
);
const Toast = defineAsyncComponent(() => import("primevue/toast"));
const ConfirmDialog = defineAsyncComponent(() =>
  import("primevue/confirmdialog")
);
const Dropdown = defineAsyncComponent(() => import("primevue/dropdown"));
const Checkbox = defineAsyncComponent(() => import("primevue/checkbox"));
const DataTable = defineAsyncComponent(() => import("primevue/datatable"));
const MultiSelect = defineAsyncComponent(() => import("primevue/multiselect"));
const Menu = defineAsyncComponent(() => import("primevue/menu"));
const Card = defineAsyncComponent(() => import("primevue/card"));
const Textarea = defineAsyncComponent(() => import("primevue/textarea"));
const VideoModal = defineAsyncComponent(() =>
  import("./components/base/VideoModal")
);

app.component("InputText", InputText);
app.component("Password", Password);
app.component("Button", Button);
app.component("Sidebar", Sidebar);
app.component("Knob", Knob);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Toast", Toast);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Dropdown", Dropdown);
app.component("Checkbox", Checkbox);
app.component("DataTable", DataTable);
app.component("MultiSelect", MultiSelect);
app.component("Menu", Menu);
app.component("Card", Card);
app.component("Textarea", Textarea);
app.component("VideoModal", VideoModal);

//Axios configuration for production
prodAPI.interceptors.request.use((request) => {
  return request;
});

prodAPI.interceptors.response.use(
  (response) => {
    if (response.data.statusCode) {
      if (
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        return Promise.resolve(response);
      }
    } else if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          if (error.response.data.data.message === "Expired token") {
            localStorage.setItem("loggedOut", "true");
            store.dispatch("logout");
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath,
              },
            });
          }
          break;
        case 403:
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

//Axios configuration for development
devAPI.interceptors.request.use((request) => {
  return request;
});

devAPI.interceptors.response.use(
  (response) => {
    if (response.data.statusCode) {
      if (
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        return Promise.resolve(response);
      }
    } else if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          if (error.response.data.data.message === "Expired token") {
            localStorage.setItem("loggedOut", "true");
            store.dispatch("logout");
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath,
              },
            });
          }
          break;
        case 403:
          break;
      }
      return Promise.reject(error.response);
    }
    console.dir(error);
  }
);

app.mount("#app");
