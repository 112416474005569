<template>
  <TheSiteHeader
    @changePassword="openChangePasswordModal"
    @openVideoModal="openVideoModal"
    class="site-header"
  >
    <template #logo>
      <img
        src="./assets/zek-logo.png"
        class="zek-logo"
        style="margin-left: 2%"
      />
    </template>
    <template #userMenu>
      <div class="user-icon" v-if="!isLoading && !isLogin">
        <Avatar icon="pi pi-user" shape="circle" size="large" />
        <span id="username-text" class="p-text-nowrap p-d-inline-flex">
          {{ this.$store.getters.username }}
        </span>
      </div>
    </template>
  </TheSiteHeader>
  <TheNavigation v-if="!isLogin" class="p-shadow-12">
    <template #navLinks>
      <ul class="nav-links" id="navLinks">
        <li class="nav-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/assets">Assets</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/vendors">Vendors</router-link>
        </li>
        <li class="nav-item" v-if="isManager">
          <router-link to="/applications">Applications</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/userfiles">Documents</router-link>
        </li>
        <li class="nav-item" v-if="isManager">
          <router-link to="/manager">Manager</router-link>
        </li>
      </ul>
    </template>
  </TheNavigation>
  <ChangePasswordModal
    :openChangeModal="showPasswordModal"
    @password-changed-successfully="PasswordChangeSucceeded"
    @password-changed-failed="PasswordChangeFailed"
    @close-modal="closeChangePasswordModal"
  />
  <Toast />

  <div id="container" class="site-container p-p-6">
    <router-view></router-view>
    <VideoModal
      id="videoModal"
      :open="showVideoModal"
      :src="videoSRC"
      :width="getVideoModalSizes.videoWidth"
      :height="getVideoModalSizes.videoHeight"
      @closeVideoModal="closeVideoModal"
    ></VideoModal>
  </div>
  <div id="siteFooter" class="site-footer">
    <TheSiteFooter />
  </div>
</template>

<script>
import TheSiteFooter from "./components/layout/TheSiteFooter.vue";
import TheNavigation from "./components/layout/TheNavigation.vue";
import TheSiteHeader from "./components/layout/TheSiteHeader.vue";
import ChangePasswordModal from "./components/layout/ChangePasswordModal.vue";
import Avatar from "primevue/avatar";
export default {
  name: "App",
  components: {
    TheSiteFooter,
    TheNavigation,
    TheSiteHeader,
    ChangePasswordModal,
    Avatar,
  },
  data() {
    return {
      isLoading: false,
      showPasswordModal: false,
      showVideoModal: false,
      videoSRC: "",
      videoWidth: 0,
      videoHeight: 0,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    didLogout() {
      return this.$store.getters["didLogout"];
    },
    username() {
      return this.$store.getters["username"];
    },
    isManager() {
      return this.$store.getters["isManager"];
    },
    isLogin() {
      return this.$route.path === "/login";
    },
    getVideoModalSizes() {
      const routePath = this.currentRoute.substring(1);
      let width = 0;
      let height = 0;
      switch (routePath) {
        case "":
          width = 1914;
          height = 932;
          break;
        case "applications":
          width = 1918;
          height = 1040;
          break;
        case "vendors":
          width = 1280;
          height = 624;
          break;
        default:
          width = 1280;
          height = 852;
          break;
      }
      return {
        videoWidth: width,
        videoHeight: height,
      };
    },
    videoMarginLeft() {
      let width = this.getVideoModalSizes().videoWidth;
      let leftMargin = width * -0.5 + "px";
      return leftMargin;
    },
    videoMarginTop() {
      let height = this.getVideoModalSizes().videoHeight;
      let topMargin = height * -0.5 + "px";
      return topMargin;
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$store.dispatch("tryLogin");
      this.isLoading = false;
    } catch (error) {
      //something here.
    }
  },

  methods: {
    openChangePasswordModal() {
      this.showPasswordModal = true;
    },
    closeChangePasswordModal() {
      this.showPasswordModal = false;
    },
    PasswordChangeSucceeded() {
      this.$toast.add({
        severity: "success",
        summary: "Password Changed.",
        life: 5000,
      });
    },
    PasswordChangeFailed() {
      this.$toast.add({
        severity: "error",
        summary: "Password update failed.",
        life: 5000,
      });
    },
    openVideoModal() {
      let srcPart = null;
      let routePath = this.currentRoute.substring(1);
      switch (routePath) {
        case "":
          srcPart = "dashboard";
          break;
        case "manager":
          srcPart = "employees";
          break;
        case "manager/employees":
          srcPart = "employees";
          break;
        case "manager/roles":
          srcPart = "roles";
          break;
        case "manager/tasks":
          srcPart = "tasks";
          break;
        default:
          srcPart = routePath;
          break;
      }
      this.videoSRC = `https://cdn.zekteck.cloud/tutorial-videos/${srcPart}_tutorial.mp4`;
      this.showVideoModal = true;
    },

    closeVideoModal() {
      this.showVideoModal = false;
    },
  },

  watch: {
    didLogout(loggedOut) {
      if (loggedOut) {
        this.$router.replace("/login");
      }
    },
  },
};
</script>

<!-- Global App styles -->
<style>
#videoModal {
  position: fixed;
  top: 50%;
  left: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  padding: 1rem;
  background-color: white;
  z-index: 100;
  border: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.site-container {
  height: 80%;
  margin-bottom: 0;
  margin-left: 150px;
  padding-top: 5%;
  background-color: #f0f3f4;
}

.nav-links {
  display: block;
  list-style-type: none;
  padding-left: 0%;
  padding-right: 5%;
  padding-top: 2vh;
}

.nav-item a.router-link-active {
  background-color: #707274;
  color: #fff;
}
.nav-item a.router-link-exact-active {
  color: #fff;
  background-color: #707274;
}

.nav-item {
  line-height: 2;
}

.nav-item:hover {
  background: #707274;
}

.nav-item a {
  text-decoration: none;
  color: #818181;
  display: block;
  font-size: 1.25rem;
  line-height: 2.5rem;
  padding: 6px 8px 6px 16px;
  margin-left: 0;
  font-weight: bold;
}

.nav-item a:hover {
  color: #fff;
}

.sidenav {
  margin-top: 140px;
}

.site-header {
  height: 140px;
}

.zek-logo {
  height: 9vh;
  margin-left: 0;
}

.site-logo {
  text-align: left;
  width: 120px;
}

.site-footer {
  width: calc(100% - 150px);
  margin-top: 0;
  background-color: #555555;
  margin-left: 150px;
  margin-right: 8px;
  height: 8vh;
  overflow: hidden;
}

.footer-copyright,
.footer-copyright a {
  height: 100%;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.user-icon {
  display: inline-flex;
  width: 80%;
  align-items: center;
  justify-content: center;
}
.help-icon-span {
  width: 20%;
  display: inline-flex;
  align-items: center;
  justify-content: left;
}

#help-icon {
  font-size: 2rem;
  color: darkblue;
  cursor: pointer;
}

.p-avatar-circle {
  display: inline-flex;
}

.clickable {
  cursor: pointer;
}

.user-menu {
  display: flex;
}

#username-text {
  padding-left: 3%;
}

.fadeOut {
  opacity: 30%;
}
</style>
