<template>
  <TaskDataTable
    :tasks="allTasks"
    @open-edit-modal="openEditTaskModal"
    @add-task-clicked="openCreateTaskModal"
  />
  <AddTaskModal
    :isPersonalTask="false"
    @task-created-successfully="taskCreatedSuccessfully"
    @task-creation-unsuccessful="taskCreateFailed"
    @close-modal="closeCreateTaskModal"
    :openCreateModal="createModalShow"
  />
  <EditTaskModal
    :selectedRow="selectedTask"
    :employees="selectedEmployees"
    :openEditModal="editModalShow"
    @task-edited-successfully="taskEditSuccess"
    @task-edit-failed="taskEditFailed"
    @close-modal="closeEditModal"
  />
  <Toast />
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import TaskDataTable from "../../components/tasks/TasksDatatable.vue";
import AddTaskModal from "../../components/dashboard/AddTaskModal.vue";
import EditTaskModal from "../../components/tasks/EditTaskModal.vue";
import ConfirmDialog from "primevue/confirmdialog";
export default {
  components: {
    TaskDataTable,
    AddTaskModal,
    EditTaskModal,
    ConfirmDialog,
  },
  data() {
    return {
      selectedEmployees: [],
      createModalShow: false,
      editModalShow: false,
      selectedTask: null,
    };
  },
  computed: {
    allTasks() {
      return this.$store.getters.getManagerTasks;
    },
  },
  methods: {
    openCreateTaskModal() {
      this.createModalShow = true;
    },

    closeCreateTaskModal() {
      this.createModalShow = false;
    },

    openEditTaskModal(task) {
      this.$store.dispatch("setSelectedTaskRow", task).then(() => {
        this.editModalShow = true;
      });
      /*       this.selectedTask = task;
      
      this.selectedEmployees = task.employees; */
    },

    closeEditModal() {
      this.$store.dispatch("setSelectedTaskRow", []);
      this.editModalShow = false;
    },

    taskCreatedSuccessfully() {
      //We are now getting the new values from the Vuex store.
      //This is a better approach as it is inherently reactive.
      this.$toast.add({
        severity: "success",
        summary: "Task Created Successfully.",
        life: 5000,
      });
      this.closeCreateTaskModal();
    },

    taskEditSuccess() {
      this.closeEditModal();
      this.$toast.add({
        severity: "success",
        summary: "Task Edited Successfully.",
        life: 5000,
      });
    },

    taskEditFailed(error) {
      this.$toast.add({
        severity: "error",
        summary: "Something went wrong. Error message: " + error.message,
        life: 5000,
      });
    },

    taskCreateFailed() {
      this.$toast.add({
        severity: "fail",
        summary: "Task Creation failed.",
        life: 5000,
      });
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      await this.$store.dispatch("getTasksForManagers").catch((error) => {
        console.dir(error);
      });
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
