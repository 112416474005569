<template>
  <div class="container" v-if="roleData != null">
    <DataTable
      :value="roleData"
      responsiveLayout="scroll"
      v-model:editingRows="editingRows"
      editMode="row"
      datakey="id"
      @rowEditInit="onRowEditInit"
      @rowEditCancel="onRowEditCancel"
      @rowEditSave="onRowEditComplete"
      :paginator="true"
      :rows="6"
    >
      <template #empty>No roles were found.</template>
      <Column field="name" header="Role Name">
        <template #editor="slotProps">
          <InputText v-model="slotProps.data.name" />
        </template>
      </Column>
      <Column field="applications" header="Role Applications">
        <template #body="slotProps">
          {{
            slotProps.data.applications !== false
              ? slotProps.data.applications.join(", ")
              : "None"
          }}
        </template>
        <template #editor>
          <MultiSelect
            :modelValue="selectedApps"
            @update:modelValue="onAppToggle"
            :options="appNames"
            optionLabel="label"
            optionValue="value"
            name="applications"
            placeholder="Select application(s) to assign"
          />
        </template>
      </Column>
      <Column field="employees" header="Role Employees">
        <template #body="slotProps">
          {{
            slotProps.data.employees !== false
              ? slotProps.data.employees.join(", ")
              : "None"
          }}
        </template>
        <template #editor>
          <MultiSelect
            :modelValue="selectedEmps"
            @update:modelValue="onEmpToggle"
            :options="empNames"
            optionLabel="label"
            optionValue="value"
            name="employees"
            placeholder="Select employee(s) to assign"
          />
        </template>
      </Column>
      <Column field="isManager" header="Manager" headerStyle="width:7rem">
        <template #body="slotProps">
          <div class="p-d-flex p-jc-center">
            <Checkbox
              v-model="slotProps.data.isManager"
              :binary="true"
              disabled="true"
            />
          </div>
        </template>
        <template #editor="slotProps">
          <Checkbox v-model="slotProps.data.isManager" :binary="true" />
        </template>
      </Column>
      <Column
        :rowEditor="true"
        style="width: 10%; min-width: 8rem"
        bodyStyle="text-align:center;"
      ></Column>
      <Column
        columnKey="{{'role-menu-' + key}}"
        headerStyle="width: 3rem; text-align:center;"
        bodyStyle="text-align:center; overflow: visible;"
      >
        <template #body="slotProps">
          <i
            class="pi pi-trash"
            style="color: red; cursor: pointer"
            @click="deleteRole(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <div class="p-col-3">
      <Button
        label="Add Role"
        icon="pi pi-plus p-button-lg"
        @click="addRole()"
      />
    </div>
  </div>
</template>
<script>
import Column from "primevue/column";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, reactive, computed, onMounted } from "vue";

export default {
  components: {
    Column,
  },
  props: ["roles"],
  setup(props, context) {
    const store = useStore();
    const originalRows = ref([]);
    const editingRows = ref([]);
    const selectedApps = ref([]);
    const selectedEmps = ref([]);
    const router = useRouter();
    const appNames = computed(() =>
      store.getters.getApplicationNames.map((name) => {
        return {
          label: name,
          value: name,
        };
      })
    );
    const empNames = computed(() =>
      store.getters.getEmployeeNames.map((name) => {
        return {
          label: name,
          value: name,
        };
      })
    );
    const roleData = computed(() => {
      return store.getters.getRoles;
    });
    const clickedRow = reactive({});

    function deleteRole(role) {
      context.emit("delete-role-clicked", role);
    }

    function addRole() {
      router.push("/manager/roles/AddRole");
    }

    function onRowEditInit(event) {
      originalRows.value[event.index] = { ...roleData.value[event.index] };
      selectedEmps.value = event.data.employees;
      selectedApps.value = event.data.applications;
    }

    function onRowEditCancel(event) {
      roleData.value[event.index] = originalRows.value[event.index];
      /*       const originalRoleIndex = roleData.value.findIndex(
        (role) => role.key === originalRows.value.key
      );
      Object.assign(roleData.value[originalRoleIndex], originalRows.value); */
    }

    const onAppToggle = (value) => {
      selectedApps.value = value;
    };

    function onEmpToggle(value) {
      selectedEmps.value = value;
    }

    async function onRowEditComplete(event) {
      event.data.applications = selectedApps.value;
      event.data.employees = selectedEmps.value;
      store.dispatch("editRole", event.data).then(() => {
        context.emit("role-edited", event);
      });
    }

    onMounted(async () => {
      store.dispatch("getRoles");
      store.dispatch("getEmployeeNames");
      store.dispatch("getApplicationNames");
    });

    return {
      roleData,
      clickedRow,
      originalRows,
      editingRows,
      selectedEmps,
      onRowEditCancel,
      onRowEditInit,
      onRowEditComplete,
      deleteRole,
      addRole,
      appNames,
      empNames,
      selectedApps,
      onEmpToggle,
      onAppToggle,
    };
  },
};
</script>

<style scoped>
.workLogs-subtable {
  padding: 1rem;
}
</style>