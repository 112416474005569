import { createStore } from "vuex";
import authModule from "./modules/auth/index.js";
import companyTasks from "./modules/dashboard/index.js";
import assetsModule from "./modules/assets/index.js";
import vendorsModule from "./modules/vendors/index.js";
import employeesModule from "./modules/employees/index.js";
import documentsModule from "./modules/userfiles/index.js";
import trainingModule from "./modules/training/index.js";
import adminModule from "./modules/admin/index.js";
import applicationsModule from "./modules/applications/index.js";
import managersModule from "./modules/managers/index.js";

const store = createStore({
  modules: {
    auth: authModule,
    companyTasks,
    assetsModule,
    vendorsModule,
    employeesModule,
    documentsModule,
    trainingModule,
    adminModule,
    applicationsModule,
    managersModule,
  },
});

export default store;
