export default {
  setScores(state, payload) {
    state.companyScore = payload?.companyScore;
    state.personalScore = payload?.personalScore;
  },
  setTasks(state, payload) {
    state.companyTasks = payload?.companyTasks;
    state.personalTasks = payload?.personalTasks;
  },
  addTask(state, payload) {
    if (payload.isPersonal) {
      if (state.personalTasks == null) {
        state.personalTasks = [payload];
      } else if (state.personalTasks != null) {
        state.personalTasks.push(payload);
      }
    } else if (!payload.isPersonal) {
      if (state.companyTasks == null) {
        state.companyTasks = [payload];
      } else if (state.companyTasks != null) {
        state.companyTasks.push(payload);
      }
    }
  },

  removeTask(state, payload) {
    let newTasks = payload.isPersonal
      ? state.personalTasks.filter((val) => val.key != payload.key)
      : state.companyTasks.filter((val) => val.key != payload.key);
    payload.isPersonal
      ? (state.personalTasks = newTasks)
      : (state.companyTasks = newTasks);
    if (state.personalTasks == null) {
      state.personalTasks = [];
    } else if (state.companyTasks == null) {
      state.companyTasks = [];
    }
    state.allTasks = [...state.personalTasks, ...state.companyTasks];
  },
  resetOverdue(state) {
    state.tasksOverdue = 0;
  },
  addOverdue(state) {
    state.tasksOverdue++;
  },

  removeOverdue(state) {
    state.taskOverdue--;
  },

  editShowEditModal(state, payload) {
    state.showEditModal = payload;
  },
  showEditModal(state, payload) {
    state.showEditModal = true;
    state.taskName = payload.name;
    state.taskDescription = payload.description;
    state.taskCategory = payload.category;
    state.taskDueDate = payload.dueDate;
    state.taskKey = payload.taskKey;
    state.taskPostID = payload.postID;
    state.rowNum = payload.rowNum;
  },
  editTask(state, payload) {
    const isPersonal = payload.isPersonal;
    const oldTask = isPersonal
      ? state.personalTasks.filter((found) => found.key == payload.key)
      : state.companyTasks.filter((found) => found.key == payload.key);
    const oldTaskIndex = isPersonal
      ? state.personalTasks.findIndex((found) => found.key == payload.key)
      : state.companyTasks.findIndex((found) => found.key == payload.key);
    const dueDate = Date.parse(payload.dueDate + "T23:59:59.999-08:00");
    const today = Date.now();
    if (dueDate > today && oldTask[0].isOverdue) {
      state.tasksOverdue--;
      payload.isOverdue = false;
    } else if (dueDate < today && !oldTask[0].isOverdue) {
      state.tasksOverdue++;
      payload.isOverdue = true;
    }
    if (isPersonal) {
      state.personalTasks[oldTaskIndex] = payload;
    } else {
      state.companyTasks[oldTaskIndex] = payload;
    }
  },
};
