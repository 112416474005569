export default {
  //We are returning the assets from the Vuex state.
  //This can be called from any component using the following:
  // this.$state.getters.getAssets
  getApplications(state) {
    return state.applications;
  },
  getApplicationNames(state) {
    return state.applicationNames;
  },
};
